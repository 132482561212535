<template>
    <div v-loading="load" 
        element-loading-spinner="el-icon-loading" 
        element-loading-text="加载中...">
        <el-button @click="GoBack" type="primary" size="small" style="margin-bottom:20px;">返回</el-button>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name='1'>
                <template #label>
                    <div class="order-tabs">
                        个人信息
                    </div>
                </template>
                <div class="mem-info">
                    <el-card>
                        <template #header>
                            <div style="text-align:left">
                                <span>个人信息</span>
                            </div>
                        </template>
                        <div class="content">
                            <div style="display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;align-items:center;font-size:14px;">
                                <div>用户名: {{memberinfo.RealName?memberinfo.RealName:memberinfo.NickName}}</div>
                                <div>出生日期: </div>
                                <div v-if="memberinfo.Gender == 'm'">性别: 男</div>
                                <div v-if="memberinfo.Gender == 'fm'">性别: 女</div>
                                <div>身份证: {{memberinfo.LicenseID}}</div>
                                <div>收货地址: {{orderinfo.Address}}</div>
                                <div>邮箱: {{orderinfo.Email}}</div>
                            </div>
                            <!--
                            <el-descriptions>
                                <el-descriptions-item label="用户名:">{{memberinfo.NickName}}</el-descriptions-item>
                                <el-descriptions-item label="出生日期:">1998-04-28</el-descriptions-item>
                                <el-descriptions-item label="性别:" v-if="memberinfo.Gender == 'm'">男</el-descriptions-item>
                                <el-descriptions-item label="性别:" v-if="memberinfo.Gender == 'fm'">女</el-descriptions-item>
                                <el-descriptions-item label="身份证:">{{memberinfo.LicenseID}}</el-descriptions-item>
                                <el-descriptions-item label="收货地址:">{{orderinfo.Address}}</el-descriptions-item>
                                <el-descriptions-item label="邮箱:">{{orderinfo.Email}}</el-descriptions-item>
                            </el-descriptions>-->
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;">
                        <template #header>
                            <div class="clearfix" style="text-align:left;display:flex;align-items:center;justify-content:space-between">
                                <span>身份证照片</span>
                                <el-button type="primary" size="small" @click="UploadID(orderinfo.ID)">上传身份证照片</el-button>
                            </div>
                        </template>
                        <div class="content" v-if="orderinfo.LicenseImg">
                            <el-image v-for="img in orderinfo.LicenseImg" :key="img" :src="img" class="license" :preview-src-list="[img]"></el-image>
                        </div>
                        <div class="content" v-else>
                            <img class="license" :src='orderinfo.LicenseImg?orderinfo.LicenseImg[0]:require(`@/assets/image/${nopic}`)'>
                            <img class="license" :src="orderinfo.LicenseImg?orderinfo.LicenseImg[1]:require(`@/assets/image/${nopic}`)">
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;">
                    <template #header>
                            <div class="clearfix" style="text-align:left">
                                <span>套餐信息</span>
                            </div>
                        </template>
                        <div class="content">
                            <el-descriptions>
                                <el-descriptions-item label="商品图片:" :span="3">
                                    <el-image :src="planinfo.pdtthumb" style="width:150px;height:auto"></el-image>
                                </el-descriptions-item>
                                <el-descriptions-item label="商品编码:">{{orderinfo.PdtCode}}</el-descriptions-item>
                                <el-descriptions-item label="商品名称:">{{orderinfo.PdtName}}</el-descriptions-item>
                                <el-descriptions-item label="套餐编码:">{{orderinfo.PlanCode}}</el-descriptions-item>
                                <el-descriptions-item label="套餐名称:">{{orderinfo.PlanTitle}}</el-descriptions-item>
                                <el-descriptions-item label="参数:">{{planinfo.params}}</el-descriptions-item>
                                <el-descriptions-item label="新旧程度:">{{planinfo.quality}}</el-descriptions-item>
                                
                                
                                <el-descriptions-item label="所属品类:">{{planinfo.category}}</el-descriptions-item>
                                <el-descriptions-item label="所属品牌:">{{planinfo.brand}}</el-descriptions-item>
                                <el-descriptions-item label="所属型号:">{{planinfo.model}}</el-descriptions-item>
                                <el-descriptions-item label="是否支持买断:">
                                    <span v-if="planinfo.buyout == 1">是</span>
                                    <span v-if="planinfo.buyout == 2">否</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="是否支持续租:">
                                    <span v-if="planinfo.rerent == 1">是</span>
                                    <span v-if="planinfo.rerent == 2">否</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="套餐类型:">
                                    <span v-if="planinfo.plantype == 1">租赁</span>
                                    <span v-if="planinfo.plantype == 2">到期赠送</span>
                                    <span v-if="planinfo.plantype == 3">售卖</span>
                                    <span v-if="planinfo.plantype == 4">定制租赁</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="租赁方式:">
                                    <span v-if="planinfo.renttype == 1">按天平均</span>
                                    <span v-if="planinfo.renttype == 2">按天阶梯</span>
                                    <span v-if="planinfo.renttype == 3">按月平均</span>
                                    <span v-if="planinfo.renttype == 4">按月阶梯</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="配送方式:">
                                    <span v-if="orderinfo.Deliver">{{orderinfo.Deliver}}</span>
                                    <span v-else>寄出到付,归还自付</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;">
                        <template #header>
                            <div class="clearfix" style="text-align:left">
                                <span>订单信息</span>
                            </div>
                        </template>
                        <div class="content">
                            <el-descriptions>
                                <el-descriptions-item label="订单号:">{{orderinfo.OrderUID}}</el-descriptions-item>
                                <el-descriptions-item label="下单时间:">{{orderinfo.AddDTime}}</el-descriptions-item>
                                <el-descriptions-item label="租期:">{{orderinfo.RentDay}} 天</el-descriptions-item>
                                <el-descriptions-item label="租赁期数:">{{orderinfo.RentCount}} 个月</el-descriptions-item>
                                <el-descriptions-item label="租赁时间:" v-if="orderinfo.Status > 5 && orderinfo.Status != 10 && orderinfo.Status != 26">{{orderinfo.ResignDTime}} 到 {{orderinfo.ReturnDTime}}</el-descriptions-item>
                                <el-descriptions-item label="应还机日:" v-if="orderinfo.Status > 5 && orderinfo.Status != 10 && orderinfo.Status != 26">{{orderinfo.ShouldReturnDTime}}</el-descriptions-item>
                                <el-descriptions-item label="订单状态:">
                                    <span v-if="orderinfo.Status == 1">等待风控审核中</span>
                                    <span v-if="orderinfo.Status == 2">等待人工审核中</span>
                                    <span v-if="orderinfo.Status == 3">待支付</span>
                                    <span v-if="orderinfo.Status == 4">待发货</span>
                                    <span v-if="orderinfo.Status == 5">待收货</span>
                                    <span v-if="orderinfo.Status == 6">待归还</span>
                                    <span v-if="orderinfo.Status == 7">归还中</span>
                                    <span v-if="orderinfo.Status == 8">定损中</span>
                                    <span v-if="orderinfo.Status == 9">已完结</span>
                                    <span v-if="orderinfo.Status == 10">已取消</span>
                                    <span v-if="orderinfo.Status == 11">强制买断中</span>
                                    <span v-if="orderinfo.Status == 12">还款逾期中</span>
                                    <span v-if="orderinfo.Status == 13">审核不通过</span>
                                    <span v-if="orderinfo.Status == 14">定损待赔付</span> 
                                    <span v-if="orderinfo.Status == 15">定损已赔付</span> 
                                    <span v-if="orderinfo.Status == 16">返修待赔付</span> 
                                    <span v-if="orderinfo.Status == 17">返修已赔付</span>
                                    <span v-if="orderinfo.Status == 19">订单取消赔付中</span>
                                    <span v-if="orderinfo.Status == 20">返修中</span>
                                    <span v-if="orderinfo.Status == 21">已买断</span>
                                    <span v-if="orderinfo.Status == 22">赔偿金已支付</span>
                                    <span v-if="orderinfo.Status == 23">强制买断</span>
                                    <span v-if="orderinfo.Status == 24">还款逾期中</span>
                                    <span v-if="orderinfo.Status == 25">买断中</span>
                                    <span v-if="orderinfo.Status == 26">下单中</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;" v-if="logistic.length > 0">
                        <template #header>
                            <div class="clearfix" style="text-align:left">
                                <span>物流信息</span>
                            </div>
                        </template>
                        <div class="content">
                            <el-table :data="logistic" border>
                                <el-table-column label="物流公司" prop="Name"></el-table-column>
                                <el-table-column label="物流单号" prop="LogisticNo"></el-table-column>
                                <el-table-column label="发货时间" prop="AddDTime"></el-table-column>
                                <!--<el-table-column label="物流状态" prop="LogisticStatus"></el-table-column>-->
                                <el-table-column label="物流类型">
                                    <template #default="scope">
                                        <span v-if="scope.row.Type == 1">寄送商品</span>
                                        <span v-if="scope.row.Type == 3">检修寄回</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template #default="scope">
                                        <el-button type="text" @click="GetLogisticDetail(scope.row.LogisticNo)" size="small">查看详情</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;" v-if="retlogistic.length > 0">
                        <template #header>
                            <div class="clearfix" style="text-align:left">
                                <span>归还物流</span>
                            </div>
                        </template>
                        <div class="content">
                            <el-table :data="retlogistic" border>
                                <el-table-column label="物流公司" prop="Name"></el-table-column>
                                <el-table-column label="物流单号" prop="LogisticNo"></el-table-column>
                                <el-table-column label="发货时间" prop="AddDTime"></el-table-column>
                                <!--<el-table-column label="物流状态" prop="LogisticStatus"></el-table-column>-->
                                <el-table-column label="物流类型">
                                    <template #default="scope">
                                        <span v-if="scope.row.Type == 2">返修商品</span>
                                        <span v-if="scope.row.Type == 4">归还商品</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template #default="scope">
                                        <el-button type="text" @click="GetLogisticDetail(scope.row.LogisticNo)" size="small">查看详情</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;">
                        <template #header>
                            <div class="card-header" style="text-align:left">
                                <span>财务信息</span>
                                <div class="option">
                                    <el-button size="small" style="text-align:right" type="primary" @click="GetOrderBill(orderinfo.ID)">查看账单</el-button>
                                    <el-button size="small" style="text-align:right" type="success" @click="GetTrade(orderinfo.ID)">查看交易记录</el-button>
                                    <el-button size="small" style="text-align:right" type="danger" @click="GetCompesation(orderinfo.ID)">查看违约赔偿金</el-button>
                                </div>
                            </div>
                        </template>
                        <div class="content">
                            <el-descriptions>
                                <el-descriptions-item label="总租金:">{{orderinfo.TotalOrderMoney*1}}</el-descriptions-item>
                                <el-descriptions-item label="已付租金:">{{(orderinfo.TotalPayRent*1).toFixed(2)}}</el-descriptions-item>
                                <el-descriptions-item label="未付租金:">{{(orderinfo.TotalOrderMoney*1 - orderinfo.TotalPayRent*1 - orderinfo.TotalDiscountMoney*1) > 0 ? (orderinfo.TotalOrderMoney*1 - orderinfo.TotalPayRent*1 - orderinfo.TotalDiscountMoney*1).toFixed(2):0}}</el-descriptions-item>
                                <el-descriptions-item label="套餐押金:">{{orderinfo.Deposit*1}}</el-descriptions-item>
                                <el-descriptions-item label="实付押金:">{{orderinfo.DepositPaid*1}}</el-descriptions-item>
                                <el-descriptions-item label="签约价:">{{planinfo.buyoutprice*1}}</el-descriptions-item>
                                <el-descriptions-item label="到期买断价:">{{(planinfo.buyoutprice*1 - orderinfo.TotalOrderMoney*1)> 0?(planinfo.buyoutprice*1 - orderinfo.TotalOrderMoney*1).toFixed(2):0}}</el-descriptions-item>
                                
                                <el-descriptions-item label="免押金额:">{{(orderinfo.SDecrease*1 + orderinfo.RDecrease*1 + orderinfo.Decrease*1 + orderinfo.ZhimaDecrease*1).toFixed(2)}}</el-descriptions-item>
                                <el-descriptions-item label="补押金:">{{orderinfo.Replenish*1}}</el-descriptions-item>
                                <el-descriptions-item label="逾期滞纳金:">{{orderinfo.Overdue*1}}</el-descriptions-item>
                                <el-descriptions-item label="赔偿金:">{{orderinfo.Compensation*1}}</el-descriptions-item>
                                <el-descriptions-item label="违约金:">{{orderinfo.Penalty*1}}</el-descriptions-item>
                                <el-descriptions-item label="减免滞纳金:">{{orderinfo.Mitigate*1}}</el-descriptions-item>
                                <el-descriptions-item label="减免赔偿金:">{{orderinfo.CompensationDecrease*1}}</el-descriptions-item>
                                <el-descriptions-item label="减免违约金:">{{orderinfo.PenaltyDecrease*1}}</el-descriptions-item>
                                <el-descriptions-item label="是否使用优惠券:">{{couponinfo?'是':'否'}}</el-descriptions-item>
                                <el-descriptions-item label="优惠券类型:" v-if="couponinfo">{{couponinfo.CouponType}}</el-descriptions-item>
                                <el-descriptions-item label="优惠券品类:" v-if="couponinfo && couponinfo.CouponType == '首期款租金卷'">{{couponinfo.UseType}}</el-descriptions-item>
                                <el-descriptions-item label="优惠券面额:" v-if="couponinfo && couponinfo.CouponType == '首期款租金卷'">{{couponinfo.CouponValue}}{{couponinfo.UseType == '抵扣'?'元':'%'}}</el-descriptions-item>
                                <el-descriptions-item label="延长天数:" v-if="couponinfo && couponinfo.CouponType == '延长还机时间'">{{couponinfo.CouponValue}}天</el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-card>
                    <el-card style="margin-top:10px;" v-if="ismerchant != 1">
                        <template #header>
                            <div class="clearfix" style="text-align:left">
                                <span>风控信息</span>
                            </div>
                        </template>
                        <div class="content">
                            <el-descriptions>
                                <el-descriptions-item label="手机号:" :span="3">{{mobileinfo.mobile}}</el-descriptions-item>
                                <el-descriptions-item label="下单次数:">{{mobileinfo.mobile_order_count}}</el-descriptions-item>
                                <el-descriptions-item label="在租订单数:">{{mobileinfo.mobile_rent_count}}</el-descriptions-item>
                                <el-descriptions-item label="逾期订单数:">{{mobileinfo.mobile_overdue_count}}</el-descriptions-item>
                                <!--<el-descriptions-item label="审核不通过订单数:">{{mobileinfo.mobile_no_count}}</el-descriptions-item>-->
                                <!--<el-descriptions-item label="审核不通过订单数:">{{mobileinfo.mobile_no_count}}</el-descriptions-item>-->
                                <el-descriptions-item label="审核不通过订单数:">0</el-descriptions-item>
                                <el-descriptions-item label="取消订单数:">{{mobileinfo.mobile_cancel_count}}</el-descriptions-item>
                                <el-descriptions-item label="其余（如待审核、待发货、待支付）:">{{mobileinfo.mobile_other_count}}</el-descriptions-item>
                            </el-descriptions>
                            <el-divider></el-divider>
                            <el-descriptions>
                                <el-descriptions-item label="身份证:" :span="3">{{licenseinfo.license}}</el-descriptions-item>
                                <el-descriptions-item label="下单次数:">{{licenseinfo.license_order_count}}</el-descriptions-item>
                                <el-descriptions-item label="在租订单数:">{{licenseinfo.license_rent_count}}</el-descriptions-item>
                                <el-descriptions-item label="逾期订单数:">{{licenseinfo.license_overdue_count}}</el-descriptions-item>
                                <!--<el-descriptions-item label="审核不通过订单数:">{{licenseinfo.license_no_count}}</el-descriptions-item>-->
                                <el-descriptions-item label="审核不通过订单数:">0</el-descriptions-item>
                                <el-descriptions-item label="取消订单数:">{{licenseinfo.license_cancel_count}}</el-descriptions-item>
                                <el-descriptions-item label="其余（如待审核、待发货、待支付）:">{{licenseinfo.license_other_count}}</el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane name='2' v-if="ismerchant != 1">
                <template #label>
                    <div class="order-tabs">
                        风控信息
                    </div>
                </template>
                <div v-if="strategy">
                    <el-card class="risk-result">
                        <template #header>
                            <span>自定义风控规则</span>
                        </template>
                        <div class="flex-block">
                            <div style="margin:10px;"><strong>风控结果:</strong> {{estimateresult}}</div>
                            <div style="margin:10px;"><strong>免押额度:</strong> {{estimate.RDecrease}}元</div>
                            <div style="margin:10px;"><strong>风控信息:</strong> 
                                <p v-if="estimate.RDecrease == 0">
                                    【风控建议】 风控不通过，风险较高。建议预收到设备价值或者拒绝。【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 500">
                                    【风控建议】 建议预收首期租金和后2期租金起，扣款前需要和客户协商同意后通过平台预收扣款，客户支付成功再发货。请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”） 【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 1000">
                                    【风控建议】 建议预收首期租金和后2期租金起，扣款前需要和客户协商同意后通过平台预收扣款，客户支付成功再发货。请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 1500">
                                    【风控建议】 风控通过，建议发货。  请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 2000">
                                    【风控建议】 风控通过，建议发货。  请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 3000">
                                    【风控建议】 风控通过，建议发货。  请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 4000">
                                    【风控建议】 风控通过，建议发货。  请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                                <p v-if="estimate.RDecrease == 5000">
                                    【风控建议】风控通过，此订单风控评估优质，建议商家优先发货。请商家注意收集并录入用户身份证正反面复印件或者照片（如有复印件原件，订单完结之前，注意保存原件）。（如用户为上门自取/安装需让租户在回单上写明“上门自取/安装，本人确认已收到设备”）【建议电审】手机/电脑等高价值设备，建议电审，如 1.通过核实用户的工作情况和累计贷款金额等，判断偿还能力；2.核实常驻地址；3.增加紧急联系人；4.需核实用户家庭/公司地址，详细到门牌号。
                                </p>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="risk-report">
                        <div style="display: flex;justify-content:space-between;align-items:center;width:50%">
                                <span style="font-size: 14px;color:#999">报告编号: {{strategy.swift_number}}</span>
                                <span style="font-size: 14px;color:#999">报告生成时间: {{formatDate(reporttime)}}</span>
                        </div>
                        <el-card class="risk-control" style="margin-top:20px;">
                            <template #header>
                                <span>审批报告</span>
                            </template>
                            <div class="flex-block-start">
                                <div class="customer-info" style="width: 30%">
                                    <div class="basic-info" style="margin-bottom: 20px;">
                                        <strong style="margin-left:20px;">客户基本信息:</strong>
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">贷前策略编号:</div>STR_BR0002732
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">验证流程编号:</div>MCP_BR0001417
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">客户编号:</div>{{riskdata.fkMemberID}}
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">姓名:</div>{{riskdata.RealName?riskdata.RealName:riskdata.Receiver}}
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">手机号:</div>{{riskdata.Mobile}}
                                    </div>
                                    <div class="info-item">
                                        <div style="width:150px;display:inline-block">身份证号:</div>{{riskdata.LicenseID}}
                                    </div>
                                </div>
                                <div class="customer-info" v-if="strategy.Rule" style="width: 70%">
                                    <div class="basic-info" style="margin-bottom: 20px;">
                                        <strong style="margin-left:20px;">风控决策结果:</strong>
                                        <span v-if="strategy.Rule.result.final_decision == 'Reject'" style="color:#ff0033;font-weight:bold"> 拒绝</span>
                                        <span v-if="strategy.Rule.result.final_decision == 'Accept'" style="color:#00cc33;font-weight:bold"> 通过</span>
                                        <span v-if="strategy.Rule.result.final_decision == 'Review'" style="color:#ff9933;font-weight:bold"> 复议</span>
                                    </div>
                                    <div v-if="antirules.length > 0">
                                        <div style="margin-bottom: 20px;">
                                            <span style="background:red;color:#fff;padding:5px;font-size:12px;width:55px;border-radius:5px;display:inline-block">风险提示: 
                                            </span>
                                            <span style="font-size:12px;display:inline-block;margin-left:10px;color:#ff0033">本次报告命中{{antirules.length + hit_count}}条异常规则信息</span>
                                        </div>
                                        <div class="hit-rule-block">
                                            <div class="info-item" v-for="rule in antirules" :key="rule">
                                                <div style="display:inline-block"><span style="color:#ff0033"> * </span>{{rule.result}}</div>
                                            </div>
                                            <div v-if="hitrules.length > 0">
                                                <div class="info-item" v-for="rule in hitrules" :key="rule">
                                                    <div style="display:inline-block" v-if='rule.weight > 0'><span style="color:#ff0033"> * </span>{{rule.result}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div v-else>
                                        <div class="noriskinfo">本次查询没有命中异常规则信息</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>验证规则集详情</span>
                            </template>
                            <div class="risk-desc">
                                <el-table :data="hitrules" border size="small" :default-sort="{ prop: 'weight', order: 'descending' }">
                                    <el-table-column prop="rulename" width="400px;">
                                        <template #header>
                                            <span>命中规则集</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="权重" width="80px" align="center" prop="weight">
                                        <template #default='scope'>
                                            <span v-if="scope.row.weight >= 70" style="display:inline-block;width:30px;text-align:center;background:#ff0033;color:#fff;border-radius:5px;">{{scope.row.weight}}</span>
                                            <span v-else style="display:inline-block;width:30px;text-align:center;background:#00cc33;color:#fff;border-radius:5px;">{{scope.row.weight}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="取值结果" prop="result"></el-table-column>
                                </el-table>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>反欺诈规则集详情</span>
                            </template>
                            <div class="risk-desc">
                                <el-table :data="antirules" border size="small" :default-sort="{ prop: 'weight', order: 'descending' }">
                                    <el-table-column prop="rulename" width="400px;">
                                        <template #header>
                                            <span>命中规则集</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="权重" width="80px" align="center" prop="weight">
                                        <template #default='scope'>
                                            <span v-if="scope.row.weight >= 70" style="display:inline-block;width:30px;text-align:center;background:#ff0033;color:#fff;border-radius:5px;">{{scope.row.weight}}</span>
                                            <span v-else style="display:inline-block;width:30px;text-align:center;background:#00cc33;color:#fff;border-radius:5px;">{{scope.row.weight}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="取值结果" prop="result"></el-table-column>
                                </el-table>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>手机三要素简版_移动联通电信</span>
                            </template>
                            <div class="risk-desc">
                                <el-descriptions border direction="vertical" :column="3"  size="small">
                                    <el-descriptions-item label="校验项">身份证号、手机号、姓名</el-descriptions-item>
                                    
                                    <el-descriptions-item label="手机号归属地">
                                        
                                        <span v-if="infoverify.TelCheck_s">
                                            <span v-if="infoverify.TelCheck_s.operation == 1">电信</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 2">联通</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 3">移动</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 4">其他</span>
                                        </span>
                                        
                                    </el-descriptions-item>
                                    <el-descriptions-item label="校验结果">
                                        <span v-if="infoverify.TelCheck_s">
                                            <span v-if="infoverify.TelCheck_s.result == 0">查无此号</span>
                                            <span v-if="infoverify.TelCheck_s.result == 1">一致</span>
                                            <span v-if="infoverify.TelCheck_s.result == 2">不一致</span>
                                            <span v-if="!infoverify.TelCheck_s.result">-</span>
                                        </span>
                                        
                                    </el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>手机信息验证</span>
                            </template>
                            <div class="risk-desc">
                                <el-descriptions direction="vertical" :column="4" :border='true'  size="small">
                                    <el-descriptions-item label="手机运营商类型">
                                        <span v-if="infoverify.TelCheck_s">
                                            <span v-if="infoverify.TelCheck_s.operation == 1">电信</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 2">联通</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 3">移动</span>
                                            <span v-if="infoverify.TelCheck_s.operation == 4">其他</span>
                                        </span>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="手机在网状态">
                                        <span v-if="infoverify.InfoVerification">
                                            <span v-if="infoverify.InfoVerification.hit_rules">
                                                <span v-for='status in infoverify.InfoVerification.hit_rules.rule_c_telstatus' :key="status">
                                                    {{status.name_rule}}
                                                </span>
                                            </span>
                                        </span>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="手机在网时长">
                                        <span v-if="infoverify.InfoVerification">
                                            <span v-if="infoverify.InfoVerification.hit_rules">
                                                <span v-for='status in infoverify.InfoVerification.hit_rules.rule_c_telperiod' :key="status">
                                                    {{status.name_rule}}
                                                </span>
                                            </span>
                                        </span>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="手机消费档次">-</el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-card>
                        <div style="font-size: 12px;color:#999">
                            <strong>注:</strong>
                            <p>1. “手机在网状态”、“手机在网时长”、“手机消费档次”数据覆盖全国范围，移动、联通、电信三家运营商数据。</p>
                            <p>2. 手机消费档次的取值返回为系数，取值为0—200，单位:元，系数越大，代表手机消费档次越高。</p>
                        </div>
                        
                        <el-card class="risk-control">
                            <template #header>
                                <span>借贷意向验证</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.applyloanstr == 1">
                                    <p style="font-size: 12px;color:#999;margin-left:15px;">借贷意向数据覆盖大部分的金融机构。机构类型包括银行、改制机构、小贷、消费类分期、现金类分期、代偿类分期和非银其它。</p>

                                    <p class='subtitle'>本人在本机构借贷意向表现</p>
                                    <div style="padding:15px;padding-top:0px;">
                                        <el-table :data="selfdata" size="small" border>
                                            <el-table-column prop="type" label="申请次数" width="150" />
                                            <el-table-column label="近7天" prop='d7'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.d7'>{{scope.row.d7}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="近15天" prop='d15'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.d15'>{{scope.row.d15}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="近1个月" prop='m1'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.m1'>{{scope.row.m1}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="近3个月" prop='m3'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.m3'>{{scope.row.m3}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="近6个月" prop='m6'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.m6'>{{scope.row.m6}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="近12个月" prop='m12'>
                                                <template #default='scope'>
                                                    <span v-if='scope.row.m12'>{{scope.row.m12}}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <div style="font-size: 12px;color:#999;margin-left:15px;">
                                        <strong>注:</strong>
                                        <p>1. 取值结果展示:按身份证号查询命中次数/按手机号查询命中次数。如:"1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</p>
                                        <p>2. 取值为"空" "0" "N"——空:无申请记录；0:本机构无申请记录但其他非银机构有申请；N:申请次数详情。</p>
                                    </div>
                                    <p class='subtitle'>本人在各个客户类型借贷意向表现</p>
                                    <div style="padding:15px;padding-top:0px;">
                                        <el-table :data="customerdata" size="small">
                                            <el-table-column prop="type" label="客户类型" width="150" />
                                            <el-table-column label="近7天">
                                                <el-table-column label="机构数" prop="d7org"></el-table-column>
                                                <el-table-column label="次数" prop="d7count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近15天">
                                                <el-table-column label="机构数" prop="d15org"></el-table-column>
                                                <el-table-column label="次数" prop="d15count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近1个月">
                                                <el-table-column label="机构数" prop="m1org"></el-table-column>
                                                <el-table-column label="次数" prop="m1count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近3个月">
                                                <el-table-column label="机构数" prop="m3org"></el-table-column>
                                                <el-table-column label="次数" prop="m3count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近6个月">
                                                <el-table-column label="机构数" prop="m6org"></el-table-column>
                                                <el-table-column label="次数" prop="m6count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近12个月">
                                                <el-table-column label="机构数" prop="m12org"></el-table-column>
                                                <el-table-column label="次数" prop="m12count"></el-table-column>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <div style="font-size: 12px;color:#999;margin-left:15px;">
                                        <strong>注:</strong>
                                        <p>1. 取值结果展示:按身份证号查询命中次数/按手机号查询命中次数。如:"1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</p>
                                        <p>2. 取值为"空" "0" "N"——空:无申请记录；0:本机构无申请记录但其他非银机构有申请；N:申请次数详情。</p>
                                    </div>
                                    <p class='subtitle'>本人在各个业务类型借贷意向表现</p>
                                    <div style="padding:15px;padding-top:0px;">
                                        <el-table :data="servicedata" size="small">
                                            <el-table-column prop="type" label="业务类型" width="150" />
                                            <el-table-column label="近7天">
                                                <el-table-column label="机构数" prop="d7org"></el-table-column>
                                                <el-table-column label="次数" prop="d7count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近15天">
                                                <el-table-column label="机构数" prop="d15org"></el-table-column>
                                                <el-table-column label="次数" prop="d15count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近1个月">
                                                <el-table-column label="机构数" prop="m1org"></el-table-column>
                                                <el-table-column label="次数" prop="m1count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近3个月">
                                                <el-table-column label="机构数" prop="m3org"></el-table-column>
                                                <el-table-column label="次数" prop="m3count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近6个月">
                                                <el-table-column label="机构数" prop="m6org"></el-table-column>
                                                <el-table-column label="次数" prop="m6count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近12个月">
                                                <el-table-column label="机构数" prop="m12org"></el-table-column>
                                                <el-table-column label="次数" prop="m12count"></el-table-column>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <div style="font-size: 12px;color:#999;margin-left:15px;">
                                        <strong>注:</strong>
                                        <p>1. 取值结果展示:按身份证号查询命中次数/按手机号查询命中次数。如:"1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</p>
                                        <p>2. 取值为"空" "N"——空:无申请记录；N:申请记录详情。</p>
                                    </div>
                                    <p class='subtitle'>本人在异常时间段借贷意向表现</p>
                                    <div style="padding:15px;padding-top:0px;">
                                        <el-table :data="timedata" size="small">
                                            <el-table-column prop="type" label="时间-机构" width="150" />
                                            <el-table-column label="近7天">
                                                <el-table-column label="机构数" prop="d7org"></el-table-column>
                                                <el-table-column label="次数" prop="d7count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近15天">
                                                <el-table-column label="机构数" prop="d15org"></el-table-column>
                                                <el-table-column label="次数" prop="d15count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近1个月">
                                                <el-table-column label="机构数" prop="m1org"></el-table-column>
                                                <el-table-column label="次数" prop="m1count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近3个月">
                                                <el-table-column label="机构数" prop="m3org"></el-table-column>
                                                <el-table-column label="次数" prop="m3count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近6个月">
                                                <el-table-column label="机构数" prop="m6org"></el-table-column>
                                                <el-table-column label="次数" prop="m6count"></el-table-column>
                                            </el-table-column>
                                            <el-table-column label="近12个月">
                                                <el-table-column label="机构数" prop="m12org"></el-table-column>
                                                <el-table-column label="次数" prop="m12count"></el-table-column>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <div style="font-size: 12px;color:#999;margin-left:15px;">
                                        <strong>注:</strong>
                                        <p>1. 取值结果展示:按身份证号查询命中次数/按手机号查询命中次数。如:"1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</p>
                                        <p>2. 取值为"空" "N"——空:无申请记录；N:申请记录详情。</p>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无借贷意向验证相关信息</div>
                                </div>
                            </div>
                            
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>法院信息详情-个人高级版</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.CourtDetailPro == 1">
                                    
                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无法院信息详情-个人高级版相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>法院裁判文书</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.executionjud == 1">
                                    <el-table :data="executionjud" size="small" border>
                                        <el-table-column label="法院名称"  prop="court"></el-table-column>
                                        <el-table-column label="法院等级"  prop="courtrank"></el-table-column>
                                        <el-table-column label="案由" prop="casecause"></el-table-column>
                                        <el-table-column label="审理程序" prop="procedure" width="80px;"></el-table-column>
                                        <el-table-column label="五级分类" prop="level" width="150px"></el-table-column>
                                        <el-table-column label="匹配度" prop="match" width="150px"></el-table-column>
                                    </el-table>
                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无法院裁判文书相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>法院被执行人限高版</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.executionlimited == 1">

                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无法院被执行人限高版相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>法院被执行人高级版</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.executionpro == 1">

                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无法院被执行人高级版相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>特殊名单验证</span>
                            </template>
                            <div v-if="strategy.Flag">
                                <div v-if="strategy.Flag.specialList_c == 1">
                                    <el-table :data="speciallist" size="small" border >
                                        <el-table-column type="index" width="50" label="序号"></el-table-column>
                                        <el-table-column label="命中特殊名单" prop="name"></el-table-column>
                                        <el-table-column label="取值结果" prop="result"></el-table-column>
                                    </el-table>
                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无特殊名单验证相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <div style="font-size: 12px;color:#999">
                            <strong>注:</strong>
                            <p>1. 一度关系:我司关系库中与本人有直接关联的人，一般是直系亲属、关系紧密的朋友；二度关系:我司关系库中与一度关系有直接关系的人。</p>
                            <p>2. 中风险，指90天以上逾期；一般风险，指30/60/90天逾期；资信不佳，指申请资料涉嫌伪冒；拒绝，指判定不良被拒绝；高风险，指曾无法联系；高危行为，指关联手机号>3或手机号关联身份证>3。</p>
                        </div>
                        <el-card class="risk-control">
                            <template #header>
                                <span>借贷风险勘测</span>
                            </template>
                            <div v-if="strategy.ApplyLoanUsury">
                                
                                <div v-if="Object.getOwnPropertyNames(strategy.ApplyLoanUsury).length > 0">
                                    <el-table :data="applydata" size="small" border style="margin-bottom: 20px;">
                                        <el-table-column label="命中借贷风险勘测信息" prop="type"></el-table-column>
                                        <el-table-column label="按身份证号" prop="id">
                                            <template #default='scope'>
                                                <span v-if="scope.row.id > 0">{{scope.row.id}} 天</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="按手机号" prop="cell">
                                            <template #default='scope'>
                                                <span v-if="scope.row.cell > 0">{{scope.row.cell}} 天</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-table :data="debtdata" size="small" border>
                                        <el-table-column label="命中借贷风险勘测信息" prop="type"></el-table-column>
                                        <el-table-column label="近7天" prop="d7">
                                            <template #default='scope'>
                                                <span v-if="scope.row.d7 > 0">{{scope.row.d7}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="近15天" prop="d15">
                                            <template #default='scope'>
                                                <span v-if="scope.row.d15 > 0">{{scope.row.d15}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="近1个月" prop="m1">
                                            <template #default='scope'>
                                                <span v-if="scope.row.m1 > 0">{{scope.row.m1}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="近3个月" prop="m3">
                                            <template #default='scope'>
                                                <span v-if="scope.row.m3 > 0">{{scope.row.m3}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="近6个月" prop="m6">
                                            <template #default='scope'>
                                                <span v-if="scope.row.m6 > 0">{{scope.row.m6}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="近12个月" prop="m12">
                                            <template #default='scope'>
                                                <span v-if="scope.row.m12 > 0">{{scope.row.m12}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="1年以前" prop="y1">
                                            <template #default='scope'>
                                                <span v-if="scope.row.y1 > 0">{{scope.row.y1}} {{scope.row.unit}}</span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div v-else>
                                    <div class="noriskinfo">查询成功，无借贷风险勘测相关信息</div>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="risk-control">
                            <template #header>
                                <span>债偿压力指数</span>
                            </template>
                            <div class="risk-desc">
                                <div v-if="strategy.Flag">
                                    <div v-if="strategy.Flag.debtrepaystress == 1">
                                        <el-descriptions :column="1" :border='true'>
                                            <el-descriptions-item label="偿债压力指数" width="50%">{{strategy.DebtRepayStress.nodebtscore}}</el-descriptions-item>
                                        </el-descriptions>
                                    </div>
                                    <div v-else>
                                        <div class="noriskinfo">查询成功，无债偿压力指数相关信息</div>
                                    </div>
                                </div>
                                
                            </div>
                        </el-card>
                        <div style="font-size: 12px;color:#999">
                            <strong>注:</strong>
                            <p>1. 用户本人当前偿债压力指数的情况，取值为0 - 100，数值越大，压力越大。</p>
                        </div>
                        <el-card class="risk-control">
                            <template #header>
                                <span>团伙欺诈排查</span>
                            </template>
                            <div class="risk-desc" v-if='strategy.Flag'>
                                <div v-if="strategy.FraudRelation_g">
                                    <div v-if="strategy.FraudRelation_g.group_num">
                                        <el-descriptions :column="2" :border='true'>
                                            <el-descriptions-item label="风险等级" width="25%">{{strategy.FraudRelation_g.list_level}}</el-descriptions-item>
                                            <el-descriptions-item label="团伙规模" width="25%">
                                                <span v-if="strategy.FraudRelation_g.group_num == 'a'">大于等于1，小于等于50</span>
                                                <span v-if="strategy.FraudRelation_g.group_num == 'b'">大于等于50，小于等于100</span>
                                                <span v-if="strategy.FraudRelation_g.group_num == 'c'">大于等于100，小于等于500</span>
                                                <span v-if="strategy.FraudRelation_g.group_num == 'd'">大于等于500</span>
                                            </el-descriptions-item>
                                        </el-descriptions>
                                    </div>
                                    <div v-else>
                                        <div class="noriskinfo">查询成功，无团伙欺诈排查相关信息</div>
                                    </div>
                                </div>
                            </div>
                            
                        </el-card>
                        <div style="font-size: 12px;color:#999">
                            <strong>注:</strong>
                            <p>1. 风险等级取值区间为3-10，取值越大，风险越高；</p>
                            <p>2. 团伙规模指同一风险等级对应群组规模的取值。</p>
                        </div>
                        
                    </el-card>
                    
                </div>
                <div v-else>
                    <div class="noriskinfo">暂无风控信息</div>
                </div>
            </el-tab-pane>
            <!--
            <el-tab-pane name="3">
                <template #label>
                    <div class="order-tabs">
                        售后记录
                    </div>
                </template>
            </el-tab-pane>-->
        </el-tabs>
    </div>
    <el-dialog v-model="logisticdetail" title="物流详情" center>
        <div>物流公司:{{expName}}</div>
        <div>物流单号:{{number}}</div>
        <el-table :data="logdetail" style="width: 100%; margin: 1em 0" border>
            <el-table-column label="时间" prop="time" width="200px"></el-table-column>
            <el-table-column label="信息" prop="status"></el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog v-model="orderbill" title="还租详情" width="70%" center>
            
            <el-table :data="billdata" border style="width: 100%; margin: 1em 0" size="medium"
                    v-loading="load" 
                element-loading-spinner="el-icon-loading" 
                element-loading-text="处理中...">
                <el-table-column label="期数" width="50px" prop="RentOrder" align="center"></el-table-column>
                <el-table-column label="应还租日" prop="PayDate" :formatter="Getdate" align="center"></el-table-column>
                <el-table-column label="应还租金" width="100px" prop="RentMoney" align="center"></el-table-column>
                <el-table-column label="实还租金" width="100px" prop="PayMoney" align="center"></el-table-column>
                <el-table-column label="未还租金" width="100px" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.PayMoney">{{(scope.row.RentMoney*1 - scope.row.PayMoney*1).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="结算情况" width="100px" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.Status == 0">未结清</span>
                        <span v-else>已结清</span>
                    </template>
                </el-table-column>
                <el-table-column label="本期结清时间" :formatter="Getdate" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.PayDTime">{{scope.row.PayDTime}}</span>
                        
                    </template>
                </el-table-column>
                <el-table-column label="本期结清方式" width="120px;" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.PayType == 1">租金结算</span>
                        <span v-if="scope.row.PayType == 2">线下入账</span>
                        <span v-if="scope.row.PayType == 3">系统代扣</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80px" align="center">
                    <template #default="scope">
                        <el-button v-if="ismerchant != 1 && scope.row.Status ==  0 && orderinfo.Status != 2" type='text' size="small" @click='AgreementPay(scope.row.ID)' :disabled="(excuting || scope.row.Btn == 1)?true:false">{{scope.row.Btn == 1?'扣款处理中':'手动扣款'}}</el-button>
                        <el-button v-if="ismerchant == 1 && scope.row.Status ==  0 && orderinfo.Status != 2 && scope.row.PayDate*1000 < Date.now()" @click='AgreementPay(scope.row.ID)' type='text' size="small" :disabled="(excuting || scope.row.Btn == 1)?true:false">{{scope.row.Btn == 1?'扣款处理中':'手动扣款'}}</el-button>
                        <span v-else></span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="summarydata" border style="width: 100%; margin: 1em 0" size="medium">
                <el-table-column label="总应还滞纳金" align="center" prop="overdue"></el-table-column>
                <el-table-column label="减免滞纳金" align="center" prop="mitigate"></el-table-column>
                <el-table-column label="已还滞纳金" align="center" prop="payoverdue"></el-table-column>
                <el-table-column label="剩余应还滞纳金" align="center" prop="needoverdue"></el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog v-model="tradebill" title="交易流水" width="70%" center>
            <el-table :data="tradedata" border style="width: 100%; margin: 1em 0" size="medium">
                <el-table-column label="订单号" prop="OrderUID"></el-table-column>
                <el-table-column label="客户名" width="100px" prop="NickName"></el-table-column>
                <el-table-column label="交易流水号" prop="BillNo"></el-table-column>
                <el-table-column label="交易时间" :formatter="Getdate" prop="AddDTime"></el-table-column>
                <el-table-column label="交易金额" width="100px">
                    <template #default="scope">
                        <span v-if="scope.row.Source == 1" style="color:rgb(112, 182, 3)">+ {{scope.row.BillMoney}}</span>
                        <span v-if="scope.row.Source == 2" style="color:rgb(255, 20, 20)">- {{scope.row.BillMoney}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易类型" width="120px">
                    <template #default="scope">
                        <span v-if="scope.row.TradeType == 1">租金</span>
                        <span v-if="scope.row.TradeType == 2">押金</span>
                        <span v-if="scope.row.TradeType == 3">违约金</span>
                        <span v-if="scope.row.TradeType == 4">买断金</span>
                        <span v-if="scope.row.TradeType == 5">定损赔偿金</span>
                        <span v-if="scope.row.TradeType == 8">强制买断金</span>
                        <span v-if="scope.row.TradeType == 6">退款</span>
                        <span v-if="scope.row.TradeType == 7">补押金</span>

                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Remark"></el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </el-dialog>

        <el-dialog v-model="compensation" title="违约金与赔偿金" width="70%" center>
            <el-table :data="compensationinfo" border style="width: 100%; margin: 1em 0" size="medium">
                <el-table-column label="类型">
                    <template #default="scope">
                        <span v-if="scope.row.BillType == 3">违约金</span>
                        <span v-if="scope.row.BillType == 5">定损赔偿金</span>
                        <span v-if="scope.row.BillType == 8">返修赔偿金</span>
                    </template>
                </el-table-column>
                <el-table-column label="金额" prop="RentMoney"></el-table-column>
                <el-table-column label="备注" prop="Remark"></el-table-column>
                <el-table-column label="结算情况">
                    <template #default="scope">
                        <span v-if="scope.row.Status == 0">未支付</span>
                        <span v-if="scope.row.Status == 1">已支付</span>
                    </template>
                </el-table-column>
                <el-table-column label="结算日期">
                    <template #default="scope">
                        <span v-if="scope.row.PayDTime">{{formatDate(scope.row.PayDTime)}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="上传身份证" v-model="uploaddialog" >
            <div class="upload-block">
                <div style="display:flex;flex-direction:column;align-items:center;margin-right:20px;">
                    <el-upload
                        :action="upload_url"
                        list-type="picture-card"
                        :on-success="UploadFrontSuccess"
                        :on-change="UploadChange"
                        :on-error="UploadError"
                        :on-remove="RemovePic"
                        :on-preview="Preview"
                        :before-upload="CheckFile"
                        :show-file-list="false"
                        >
                        <template #default>
                            <img :src='licensefront' style="width: 360px;height:240px;" v-if="licensefront">
                            <img src='@\assets\image\front.png' style="width: 85%;" v-else>
                        </template>
                    </el-upload>
                    <div style="margin-top:20px;">上传身份证正面</div>
                </div>
                <div style="display:flex;flex-direction:column;align-items:center">
                    <el-upload
                        :action="upload_url"
                        list-type="picture-card"
                        :on-success="UploadBackSuccess"
                        :on-change="UploadChange"
                        :on-error="UploadError"
                        :on-remove="RemovePic"
                        :on-preview="Preview"
                        :show-file-list="false"
                        >
                        <template #default>
                            <img :src='licenseback' style="width: 360px;height:240px;" v-if="licenseback">
                            <img src='@\assets\image\back.png' style="width: 85%;" v-else>
                        </template>
                        
                    </el-upload>
                    <div style="margin-top:20px;">上传身份证反面</div>
                </div>
            </div>
            <template #footer>
                <el-button @click="SaveLicense" type="primary">保存</el-button>
                <el-button @click="uploaddialog = false;">取消</el-button>
            </template>
            
        </el-dialog>
</template>
<style scoped>
.subtitle {
    font-size: 12px;
    font-weight:700;
    margin-left:15px;
    margin-top: 15px;
}
.mem-info >>> .el-card__header {
    padding: 10px 15px;
}
.hit-rule-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 250px;
}
.flex-block-start {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height:320px;
}
.noriskinfo {
    color:#4e5158;
    padding: 10px 20px;
    font-size:14px;
}
.risk-desc >>> .el-table th, .risk-control >>> .el-table th
{
    background: #c7c4c4;
    color: #4e5158;
} 
.info-item {
    color:#606266;
    line-height:40px;
    padding:0 12px 0 0;
    font-size:14px;
}
.subtitle::before {
    content:'';
    display:inline-block;
    width: 3px;
    height: 12px;
    vertical-align: middle;
    margin-right: 15px;
    background: #449ae7
}
div[class='basic-info']::before {
    content:'';
    display:inline-block;
    width: 5px;
    height: 15px;
    vertical-align: middle;
    background: #449ae7

}
.customer-info {
    margin-left: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    height:320px;
}

.customer-info:first-child{
    border-right: 3px solid #e7e7e7
}

.risk-result, .risk-report {
    margin-bottom: 20px;
}
.risk-result >>> .el-card__header {
    padding: 10px 15px;
    text-align: center;
    font-weight: bold;
    background: #ff6600;
    color: #fff;
}
.risk-report >>> .el-card__header {
    padding: 10px 15px;
    color: #4e5158;
}
.risk-desc >>> .el-descriptions__label.is-bordered-label {
    background: #c7c4c4;
    color: #4e5158;
    
}
.risk-desc >>> .el-descriptions .is-bordered th {
    padding: 10px 8px;
    
}
.risk-control {
    margin-bottom: 20px;
}
.risk-control >>> .el-card__header {
    background: #c8e2f5;
    padding: 10px 15px;
    text-align: center;
    font-weight: bold;
}
.risk-control >>> .el-card__body {
    
    padding: 0px;

}
.flex-block{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.order-tabs {
    padding-bottom: 20px;
    padding-left: 22px;
    padding-right: 22px;
}

.upload-block {
    display:flex;
    flex-direction:row;
    align-items: center;
}

>>>.el-upload--picture-card {
    width: 360px;
    height: 240px;
    
    border: none;
    background-color: #62aaf5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.license {
    width: 430px;
    height: 280px;
    padding:10px;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.option {
    
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant";
export default {
    data(){
        return{
            
            excuting:false,
            couponinfo:[],
            hit_count:0,
            selfdata:[],
            speciallist:[],
            executionjud:[],
            debtdata:[],
            applydata:[],
            customerdata:[],
            servicedata:[],
            timedata:[],
            antirules:[],
            hitrules:[],
            riskdata:'',
            reporttime:'',
            estimate:'',
            estimateresult:'',
            strategy:'',
            infoverify:'',
            curorderid:'',
            activeName:'1',
            uploaddialog:false,
            curpage:1,
            totalcount:'',
            pagesize:10,
            tradebill:false,
            tradedata:[],
            billdata:[],
            summarydata:[{
                overdue:0,
                mitigate:0,
                needoverdue:0,
                payoverdue:0
            }],
            overdue:'',
            mitigate:'',
            orderbill:false,
            load:false,
            expName:'',
            number:'',
            logisticdetail:false,
            orderinfo:'',
            memberinfo:'',
            planinfo:'',
            logistic:'',
            retlogistic:'',
            nopic:'nopic.png',
            compensationinfo:[],
            compensation:false,
            licenseinfo:'',
            mobileinfo:'',
            uploadid:constant.uploadid,
            FileList:[],
            licensefront:"",
            licenseback:"",
            upload_url:constant.upload_temp_img_url,
            memberid:'',
            retparams:{
                curpage:'',
                pagesize:'',
                status:'',
                range:'',
                keyword:'',
                type:'',
            }
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        let orderid = this.$route.query.id;
        this.curorderid = orderid;
        var curpage = this.$route.query.curpage;
        if(curpage)
            this.retparams.curpage = curpage;
        var pagesize = this.$route.query.pagesize;
        if(pagesize)
            this.retparams.pagesize = pagesize;
        var status = this.$route.query.status;
        if(status)
            this.retparams.status = status;
        var range = this.$route.query.range;
        if(range)
            this.retparams.range = range;
        var keyword = this.$route.query.keyword;
        if(keyword)
            this.retparams.keyword = keyword;
        var type = this.$route.query.type;
        if(type)
            this.retparams.type = type;
        console.log(orderid);
        this.load = true;
        this.axios.get(constant.get_orderdetail,{
            headers: {'Content-Type': 'application/json'},
            params:{
                orderid:orderid
            }
        }).then((response)=>{
            console.log(response.data);
            this.orderinfo = response.data.orderinfo;
            this.memberinfo = response.data.meminfo;
            this.planinfo = response.data.orderinfo.PlanProp;
            this.logistic = response.data.logistic;
            this.retlogistic = response.data.retlogistic;
            this.licenseinfo = response.data.licenseinfo;
            this.mobileinfo = response.data.mobileinfo;
            this.couponinfo = response.data.coupon;
            this.load = false;
        });
    },
    methods:{
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.GetTrade(this.curorderid);
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.GetTrade(this.curorderid);
        },
        CheckFile(file){
			
            console.log(file);
            if(file.type != "image/jpg" && file.type !="image/png" && file.type != "image/jpeg"){
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
			if((file.size*1)/1024 > 2048){
				this.$message.error("当前上传图片尺寸过大,建议图片大小在2MB内");
				return false;
			}
        },
        AgreementPay(orderbillid){
            console.log(orderbillid);
            this.excuting = true;
            this.load = true;
            this.axios.get(constant.agreementpay,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    orderbillid:orderbillid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.load = false;
                if(response.data == 'OK'){
                    this.excuting = false;
                    this.$message.success("代扣成功");
                    this.GetOrderBill(this.orderinfo.ID);
                    //this.$router.go(0);
                }else{
                    if(response.data == 'DEALING'){
                        this.$message.error("支付宝扣款处理中");
                        this.excuting = 1;
                        this.GetOrderBill(this.orderinfo.ID);
                    }else{
                        this.$message.error(response.data);
                        this.GetOrderBill(this.orderinfo.ID);
                    }
                    
                }
            });
        },
        handleClick(){
            console.log(this.activeName);
            if(this.activeName == 1){
                this.load = true;
                this.axios.get(constant.get_orderdetail,{
                    headers: {'Content-Type': 'application/json'},
                    params:{
                        orderid:this.curorderid
                    }
                }).then((response)=>{
                    console.log(response.data);
                    this.orderinfo = response.data.orderinfo;
                    this.memberinfo = response.data.meminfo;
                    this.planinfo = response.data.orderinfo.PlanProp;
                    this.logistic = response.data.logistic;
                    this.retlogistic = response.data.retlogistic;
                    this.licenseinfo = response.data.licenseinfo;
                    this.mobileinfo = response.data.mobileinfo;
                    this.load = false;
                });
            }
            if(this.activeName == 2){
                this.hit_count = 0,
                this.selfdata = [],
                this.speciallist = [],
                this.executionjud = [],
                this.debtdata = [],
                this.applydata = [],
                this.customerdata = [],
                this.servicedata = [],
                this.timedata = [],
                this.antirules = [],
                this.hitrules = [],
                this.axios.get(constant.riskinfo,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        orderid: this.curorderid
                    }
                }).then((response)=>{
                    console.log(response.data);
                    this.riskdata = response.data;
                    //console.log(this.riskdata);
                    
                    this.infoverify = JSON.parse(response.data.InfoverifyResult);
                    this.strategy = JSON.parse(response.data.StrategyResult);
                    this.estimate = JSON.parse(response.data.RiskEstimate);
                    this.reporttime = response.data.EstimateDTime;
                    let result =  this.estimate.result;
                    console.log('result--' + result);
                    //let result = this.estimate.result;
                    let str = '';
                    for(var key in result){
                        str += result[key] + key;
                    }
                    this.estimateresult = str;
                    console.log(this.estimateresult);
                    console.log(this.infoverify);
                    console.log(this.strategy);
                    console.log(this.estimate);
                    //信息验证命中规则集
                    let hit = this.infoverify.InfoVerification.hit_rules;
                    
                    for(key in hit){
                        
                        let rulename = '';
                        if(key == 'rule_c_telcheck_s'){
                            
                            rulename = '信息验证规则-手机三要素简版—移动联通电信';
                            
                        }else if(key == 'rule_c_telperiod'){
                            rulename = '信息验证规则-手机在网时长';
                            
                        }else if(key == 'rule_c_telstatus'){
                            rulename = '信息验证规则-手机在网状态';
                            
                        }else if(key == 'rule_c_courtdetailpro'){
                            rulename = '信息验证规则-法院信息详情-个人高级版';
                            
                        }
                        let obj = hit[key];
                        for(let objkey in obj){
                            let curobj = obj[objkey];
                            let retobj = new Object();
                            retobj.rulename = rulename;
                            retobj.weight = curobj.weight;
                            if(curobj.weight > 0)
                                this.hit_count = this.hit_count + 1;
                            retobj.result = curobj.name_rule;
                            this.hitrules.push(retobj);
                        }
                    }
                    //console.log(this.hitrules);
                    //反欺诈命中规则集
                    
                    let anti = this.strategy.Rule.hit_rules;
                    //console.log("anti");
                    //console.log(anti);
                    for(key in anti){
                        
                        let rulename = '';
                        if(key == 'ruleapplyloan'){
                            rulename = '反欺诈规则-借贷意向验证-通用';
                        }else if(key == 'ruleexecutionlimited'){
                            rulename = '反欺诈规则-法院被执行人限高版';
                        }else if(key == 'ruleexecutionjud'){
                            rulename = '反欺诈规则-法院裁判文书';
                        }else if(key == 'ruleexecutionpro'){
                            rulename = '反欺诈规则-法院被执行人高级版';
                        }else if(key == 'rulespeciallist_c'){
                            rulename = '反欺诈规则-特殊名单验证';
                        }else if(key == 'rule_d_applyloanusury'){
                            rulename = '反欺诈规则-借贷风险勘测';
                        }else if(key == 'rule_d_debtrepaystress'){
                            rulename = '反欺诈规则-偿债压力指数';
                        }else if(key == 'rule_d_fraudrelation_g'){
                            rulename = '反欺诈规则-团伙欺诈排查（通用版）';
                        }
                        
                        //console.log(retobj);
                        
                        let obj = anti[key];
                        
                        for(key in obj){
                            let curobj = obj[key];
                            
                            let retobj = new Object();
                            retobj.rulename = rulename;
                            retobj.weight = curobj.weight;
                            retobj.result = curobj.name_rule;
                            
                            this.antirules.push(retobj);
                            
                        }
                    }
                    //console.log(this.antirules);
                    //借贷意向
                    let bank_self = new Object();
                    bank_self.type = '银行';
                    let nbank_self = new Object();
                    nbank_self.type = '非银行';
                    let bank_night = new Object();
                    bank_night.type = "夜间-银行";
                    let bank_week = new Object();
                    bank_week.type = "周末-银行";
                    let nbank_night = new Object();
                    nbank_night.type = "夜间-非银";
                    let nbank_week = new Object();
                    nbank_week.type = "周末-非银";
                    let bank = new Object();
                    bank.type = "银行汇总";
                    let nbank = new Object();
                    nbank.type = "非银汇总";
                    let caon = new Object();
                    caon.type = "线上现金分期";
                    let pdl = new Object();
                    pdl.type =  "线上小额现金贷";
                    let rel = new Object();
                    rel.type = "信用卡（类信用卡）";
                    let caoff = new Object();
                    caoff.type = "线下现金分期";
                    let cooff = new Object();
                    cooff.type = "线下消费分期";
                    let af = new Object();
                    af.type = "汽车金融";
                    let coon = new Object();
                    coon.type = "线上消费分期";
                    for(key in this.strategy.ApplyLoanStr){
                        if(key == 'd7'){
                            let d7_id = this.strategy.ApplyLoanStr[key].id;
                            let d7_cell = this.strategy.ApplyLoanStr[key].cell;
                            let d7_caon_org_str = '';
                            let d7_caon_all_str = '';
                            let d7_pdl_org_str = '';
                            let d7_pdl_all_str = '';
                            let d7_rel_org_str = "";
                            let d7_rel_all_str = "";
                            let d7_caoff_all_str = "";
                            let d7_caoff_org_str = "";
                            let d7_cooff_all_str = "";
                            let d7_cooff_org_str = "";
                            let d7_af_all_str = "";
                            let d7_af_org_str = "";
                            let d7_coon_all_str = "";
                            let d7_coon_org_str = "";
                            let d7_bank_all_str = "";
                            let d7_bank_org_str = "";
                            let d7_nbank_all_str = "";
                            let d7_nbank_org_str = "";
                            let d7_bank_week_all_str = "";
                            let d7_bank_week_org_str = "";
                            let d7_nbank_week_all_str = "";
                            let d7_nbank_week_org_str = "";
                            let d7_bank_night_all_str = "";
                            let d7_bank_night_org_str = '';
                            let d7_nbank_night_all_str = "";
                            let d7_nbank_night_org_str = "";
                            let d7_bank_self_str = "";
                            let d7_nbank_self_str = "";
                            if(d7_id){
                                if(d7_id.bank){
                                    if(d7_id.bank.selfnum)
                                        d7_bank_self_str = d7_id.bank.selfnum + "/";
                                    if(d7_id.bank.allnum)
                                        d7_bank_all_str = d7_id.bank.allnum + "/";
                                    if(d7_id.bank.orgnum)
                                        d7_bank_org_str = d7_id.bank.orgnum + "/";
                                    /*
                                    let d7_bank_totalall = 0;
                                    if(d7_id.bank.tra_allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_id.bank.tra_allnum*1;
                                    if(d7_id.bank.ret_allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_id.bank.ret_allnum*1;
                                    if(d7_id.bank.allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_id.bank.allnum*1;
                                    let d7_bank_totalorg = 0;
                                    if(d7_id.bank.tra_orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_id.bank.tra_orgnum*1;
                                    if(d7_id.bank.ret_orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_id.bank.ret_orgnum*1;
                                    if(d7_id.bank.orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_id.bank.orgnum*1;
                                    d7_bank_all_str = d7_bank_totalall + "/";
                                    d7_bank_org_str = d7_bank_totalorg + "/";*/
                                    d7_bank_week_all_str = d7_id.bank.week_allnum + "/";
                                    d7_bank_week_org_str = d7_id.bank.week_orgnum + "/";
                                    d7_bank_night_all_str = d7_id.bank.night_allnum + "/";
                                    d7_bank_night_org_str = d7_id.bank.night_orgnum + "/";
                                }else{
                                    d7_bank_all_str = "-/";
                                    d7_bank_org_str = "-/";
                                    d7_bank_week_all_str = "-/";
                                    d7_bank_week_org_str = "-/";
                                    d7_bank_night_all_str = "-/";
                                    d7_bank_night_org_str = "-/";
                                }
                                if(d7_id.nbank){
                                    if(d7_id.nbank.selfnum)
                                        d7_nbank_self_str = d7_id.nbank.selfnum + "/";
                                    if(d7_id.nbank.allnum)
                                        d7_nbank_all_str = d7_id.nbank.allnum + "/";
                                    if(d7_id.nbank.orgnum)
                                        d7_nbank_org_str = d7_id.nbank.orgnum + "/";
                                    /*
                                    let d7_nbank_totalall = 0;
                                    if(d7_id.nbank.tra_allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_id.nbank.tra_allnum*1;
                                    if(d7_id.nbank.ret_allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_id.nbank.ret_allnum*1;
                                    if(d7_id.nbank.allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_id.nbank.allnum*1;
                                    let d7_nbank_totalorg = 0;
                                    if(d7_id.nbank.tra_orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_id.nbank.tra_orgnum*1;
                                    if(d7_id.nbank.ret_orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_id.nbank.ret_orgnum*1;
                                    if(d7_id.nbank.orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_id.nbank.orgnum*1;
                                    d7_nbank_all_str = d7_nbank_totalall + "/";
                                    d7_nbank_org_str = d7_nbank_totalorg + "/";*/
                                    d7_nbank_week_all_str = d7_id.nbank.week_allnum + "/";
                                    d7_nbank_week_org_str = d7_id.nbank.week_orgnum + "/";
                                    d7_nbank_night_all_str = d7_id.nbank.night_allnum + "/";
                                    d7_nbank_night_org_str = d7_id.nbank.night_orgnum + "/";
                                }else{
                                    d7_nbank_all_str = "-/";
                                    d7_nbank_org_str = "-/";
                                    d7_nbank_week_all_str = "-/";
                                    d7_nbank_week_org_str = "-/";
                                    d7_nbank_night_all_str = "-/";
                                    d7_nbank_night_org_str = "-/";
                                }
                                if(d7_id.caon){
                                    if(d7_id.caon.orgnum)
                                        d7_caon_org_str = d7_id.caon.orgnum + '/';
                                    if(d7_id.caon.allnum)
                                        d7_caon_all_str = d7_id.caon.allnum + '/';
                                }else{
                                    d7_caon_org_str = '-/';
                                    d7_caon_all_str = "-/";
                                }
                                if(d7_id.pdl){
                                    d7_pdl_org_str = d7_id.pdl.orgnum + '/';
                                    d7_pdl_all_str = d7_id.pdl.allnum + '/';
                                }else{
                                    d7_pdl_org_str = '-/';
                                    d7_pdl_all_str = "-/";
                                }
                                if(d7_id.rel){
                                    d7_rel_org_str = d7_id.rel.orgnum + '/';
                                    d7_rel_all_str = d7_id.rel.allnum + '/';
                                }
                                else{
                                    d7_rel_org_str = '-/';
                                    d7_rel_all_str = "-/";
                                }
                                if(d7_id.caoff){
                                    d7_caoff_org_str = d7_id.caoff.orgnum + '/';
                                    d7_caoff_all_str = d7_id.caoff.allnum + '/';
                                }else{
                                    d7_caoff_org_str = '-/';
                                    d7_caoff_all_str = "-/";
                                }
                                if(d7_id.cooff){
                                    d7_cooff_org_str = d7_id.cooff.orgnum + '/';
                                    d7_cooff_all_str = d7_id.cooff.allnum + '/';
                                }else{
                                    d7_cooff_org_str = '-/';
                                    d7_cooff_all_str = "-/";
                                }
                                if(d7_id.af){
                                    d7_af_org_str = d7_id.af.orgnum + '/';
                                    d7_af_all_str = d7_id.af.allnum + '/';
                                }else{
                                    d7_af_org_str = '-/';
                                    d7_af_all_str = "-/";
                                }
                                if(d7_id.coon){
                                    d7_coon_org_str = d7_id.coon.orgnum + '/';
                                    d7_coon_all_str = d7_id.coon.allnum + '/';
                                }else{
                                    d7_coon_org_str = '-/';
                                    d7_coon_all_str = "-/";
                                }
                            }
                            if(d7_cell){
                                if(d7_cell.bank){
                                    /*
                                    let d7_bank_totalall = 0;
                                    if(d7_cell.bank.tra_allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_cell.bank.tra_allnum*1;
                                    if(d7_cell.bank.ret_allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_cell.bank.ret_allnum*1;
                                    if(d7_cell.bank.allnum)
                                        d7_bank_totalall = d7_bank_totalall + d7_cell.bank.allnum*1;
                                    let d7_bank_totalorg = 0;
                                    if(d7_cell.bank.tra_orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_cell.bank.tra_orgnum*1;
                                    if(d7_cell.bank.ret_orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_cell.bank.ret_orgnum*1;
                                    if(d7_cell.bank.orgnum)
                                        d7_bank_totalorg = d7_bank_totalorg + d7_cell.bank.orgnum*1;
                                    d7_bank_all_str = d7_bank_all_str + d7_bank_totalall;
                                    d7_bank_org_str = d7_bank_org_str + d7_bank_totalorg;*/
                                    if(d7_cell.bank.selfnum)
                                        d7_bank_self_str = d7_bank_self_str + d7_cell.bank.selfnum;
                                    if(d7_cell.bank.allnum)
                                        d7_bank_all_str = d7_bank_all_str + d7_cell.bank.allnum;
                                    if(d7_cell.bank.orgnum)
                                        d7_bank_org_str = d7_bank_org_str + d7_cell.bank.orgnum;
                                    d7_bank_week_all_str = d7_bank_week_all_str + d7_cell.bank.week_allnum;
                                    d7_bank_week_org_str = d7_bank_week_org_str + d7_cell.bank.week_orgnum;
                                    d7_bank_night_all_str = d7_bank_night_all_str + d7_cell.bank.night_allnum;
                                    d7_bank_night_org_str = d7_bank_night_org_str + d7_cell.bank.night_orgnum;
                                }else{
                                    d7_bank_all_str = d7_bank_all_str + "-";
                                    d7_bank_org_str = d7_bank_org_str + "-";
                                    d7_bank_week_all_str = d7_bank_week_all_str + "-";
                                    d7_bank_week_org_str = d7_bank_week_org_str + "-";
                                    d7_bank_night_all_str = d7_bank_night_all_str + "-";
                                    d7_bank_night_org_str = d7_bank_night_org_str + "-";
                                }
                                if(d7_cell.nbank){
                                    /*
                                    let d7_nbank_totalall = 0;
                                    if(d7_cell.nbank.tra_allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_cell.nbank.tra_allnum*1;
                                    if(d7_cell.nbank.ret_allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_cell.nbank.ret_allnum*1;
                                    if(d7_cell.nbank.allnum)
                                        d7_nbank_totalall = d7_nbank_totalall + d7_cell.nbank.allnum*1;
                                    let d7_nbank_totalorg = 0;
                                    if(d7_cell.nbank.tra_orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_cell.nbank.tra_orgnum*1;
                                    if(d7_cell.nbank.ret_orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_cell.nbank.ret_orgnum*1;
                                    if(d7_cell.nbank.orgnum)
                                        d7_nbank_totalorg = d7_nbank_totalorg + d7_cell.nbank.orgnum*1;
                                    d7_nbank_all_str = d7_nbank_all_str + d7_nbank_totalall;
                                    d7_nbank_org_str = d7_nbank_org_str + d7_nbank_totalorg;*/
                                    if(d7_cell.nbank.selfnum)
                                        d7_nbank_self_str = d7_nbank_self_str + d7_cell.nbank.selfnum;
                                     if(d7_cell.nbank.allnum)
                                        d7_nbank_all_str = d7_nbank_all_str + d7_cell.nbank.allnum;
                                    if(d7_cell.nbank.orgnum)
                                        d7_nbank_org_str = d7_nbank_org_str + d7_cell.nbank.orgnum;
                                    d7_nbank_week_all_str = d7_nbank_week_all_str + d7_cell.nbank.week_allnum;
                                    d7_nbank_week_org_str = d7_nbank_week_org_str + d7_cell.nbank.week_orgnum;
                                    d7_nbank_night_all_str = d7_nbank_night_all_str + d7_cell.nbank.night_allnum;
                                    d7_nbank_night_org_str = d7_nbank_night_org_str + d7_cell.nbank.night_orgnum;
                                }else{
                                    d7_nbank_all_str = d7_nbank_all_str + "-";
                                    d7_nbank_org_str = d7_nbank_org_str + "-";
                                    d7_nbank_week_all_str = d7_nbank_week_all_str + "-";
                                    d7_nbank_week_org_str = d7_nbank_week_org_str + "-";
                                    d7_nbank_night_all_str = d7_nbank_night_all_str + "-";
                                    d7_nbank_night_org_str = d7_nbank_night_org_str + "-";
                                }
                                if(d7_cell.caon){
                                    if(d7_cell.caon.orgnum)
                                        d7_caon_org_str = d7_caon_org_str + d7_cell.caon.orgnum;
                                    if(d7_cell.caon.allnum)
                                        d7_caon_all_str = d7_caon_all_str + d7_cell.caon.allnum;
                                }else{
                                    d7_caon_org_str = d7_caon_org_str + '-';
                                    d7_caon_all_str = d7_caon_all_str + '-';
                                }
                                if(d7_cell.pdl){
                                    d7_pdl_org_str = d7_pdl_org_str + d7_cell.pdl.orgnum;
                                    d7_pdl_all_str = d7_pdl_all_str + d7_cell.pdl.allnum;
                                }else{
                                    d7_pdl_org_str = d7_pdl_org_str + '-';
                                    d7_pdl_all_str = d7_pdl_all_str + '-';
                                }
                                if(d7_cell.rel){
                                    d7_rel_org_str = d7_rel_org_str + d7_cell.rel.orgnum;
                                    d7_rel_all_str = d7_rel_all_str + d7_cell.rel.allnum;
                                }
                                else{
                                    d7_rel_org_str = d7_rel_org_str + '-';
                                    d7_rel_all_str = d7_rel_all_str + '-';
                                }
                                if(d7_cell.caoff){
                                    d7_caoff_org_str = d7_caoff_org_str + d7_cell.caoff.orgnum;
                                    d7_caoff_all_str = d7_caoff_all_str + d7_cell.caoff.allnum;
                                }else{
                                    d7_caoff_org_str = d7_caoff_org_str + '-';
                                    d7_caoff_all_str = d7_caoff_all_str + '-';
                                }
                                if(d7_cell.cooff){
                                    d7_cooff_org_str = d7_cooff_org_str + d7_cell.cooff.orgnum;
                                    d7_cooff_all_str = d7_cooff_all_str + d7_cell.cooff.allnum;
                                }else{
                                    d7_cooff_org_str = d7_cooff_org_str + '-';
                                    d7_cooff_all_str = d7_cooff_all_str + '-';
                                }
                                if(d7_cell.af){
                                    d7_af_org_str = d7_af_org_str + d7_cell.af.orgnum;
                                    d7_af_all_str = d7_af_all_str + d7_cell.af.allnum;
                                }else{
                                    d7_af_org_str = d7_af_org_str + '-';
                                    d7_af_all_str = d7_af_all_str + '-';
                                }
                                if(d7_cell.coon){
                                    d7_coon_org_str = d7_coon_org_str + d7_cell.coon.orgnum;
                                    d7_coon_all_str = d7_coon_all_str + d7_cell.coon.allnum;
                                }else{
                                    d7_coon_org_str = d7_coon_org_str + '-';
                                    d7_coon_all_str = d7_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!d7_id && !d7_cell){
                                d7_bank_all_str = "-";
                                d7_bank_org_str = "-";
                                d7_bank_week_all_str = "-";
                                d7_bank_week_org_str = "-";
                                d7_bank_night_all_str = "-";
                                d7_bank_night_org_str = "-";
                                d7_nbank_all_str = "-";
                                d7_nbank_org_str = "-";
                                d7_nbank_week_all_str = "-";
                                d7_nbank_week_org_str = "-";
                                d7_nbank_night_all_str = "-";
                                d7_nbank_night_org_str = "-";
                                d7_caon_org_str = '-';
                                d7_caon_all_str = '-';
                                d7_pdl_org_str = '-';
                                d7_pdl_all_str = '-';
                                d7_rel_org_str = '-';
                                d7_rel_all_str = '-';
                                d7_caoff_org_str = '-';
                                d7_caoff_all_str = '-';
                                d7_cooff_org_str = '-';
                                d7_cooff_all_str = '-';
                                d7_af_org_str = '-';
                                d7_af_all_str = '-';
                                d7_coon_org_str = '-';
                                d7_coon_all_str = '-';
                            }
                            bank.d7org = d7_bank_org_str;
                            bank.d7count = d7_bank_all_str;
                            nbank.d7org = d7_nbank_org_str;
                            nbank.d7count = d7_nbank_all_str;
                            bank_night.d7org = d7_bank_night_org_str;
                            bank_night.d7count = d7_bank_night_all_str;
                            nbank_night.d7org = d7_nbank_night_org_str;
                            nbank_night.d7count = d7_nbank_night_all_str;
                            bank_week.d7org = d7_bank_week_org_str;
                            bank_week.d7count = d7_bank_week_all_str;
                            nbank_week.d7org = d7_nbank_week_org_str;
                            nbank_week.d7count = d7_nbank_week_all_str;
                            caon.d7org = d7_caon_org_str;
                            caon.d7count = d7_caon_all_str;
                            pdl.d7org = d7_pdl_org_str;
                            pdl.d7count = d7_pdl_all_str;
                            rel.d7org = d7_rel_org_str;
                            rel.d7count = d7_rel_all_str;
                            caoff.d7org = d7_caoff_org_str;
                            caoff.d7count = d7_caoff_all_str;
                            cooff.d7org = d7_cooff_org_str;
                            cooff.d7count = d7_cooff_all_str;
                            af.d7org = d7_af_org_str;
                            af.d7count = d7_af_all_str;
                            coon.d7org = d7_coon_org_str;
                            coon.d7count = d7_coon_all_str;
                            bank_self.d7 = d7_bank_self_str;
                            nbank_self.d7 = d7_nbank_self_str;
                        }
                        
                        if(key == 'd15'){
                            let d15_id = this.strategy.ApplyLoanStr[key].id;
                            let d15_cell = this.strategy.ApplyLoanStr[key].cell;
                            let d15_caon_org_str = '';
                            let d15_caon_all_str = '';
                            let d15_pdl_org_str = '';
                            let d15_pdl_all_str = '';
                            let d15_rel_org_str = "";
                            let d15_rel_all_str = "";
                            let d15_caoff_all_str = "";
                            let d15_caoff_org_str = "";
                            let d15_cooff_all_str = "";
                            let d15_cooff_org_str = "";
                            let d15_af_all_str = "";
                            let d15_af_org_str = "";
                            let d15_coon_all_str = "";
                            let d15_coon_org_str = "";
                            let d15_bank_all_str = "";
                            let d15_bank_org_str = "";
                            let d15_nbank_all_str = "";
                            let d15_nbank_org_str = "";
                            let d15_bank_week_all_str = "";
                            let d15_bank_week_org_str = "";
                            let d15_nbank_week_all_str = "";
                            let d15_nbank_week_org_str = "";
                            let d15_bank_night_all_str = "";
                            let d15_bank_night_org_str = '';
                            let d15_nbank_night_all_str = "";
                            let d15_nbank_night_org_str = "";
                            let d15_bank_self_str = "";
                            let d15_nbank_self_str = "";
                            if(d15_id){
                                if(d15_id.bank){
                                    if(d15_id.bank.selfnum)
                                        d15_bank_self_str = d15_id.bank.selfnum + "/";
                                    if(d15_id.bank.allnum)
                                        d15_bank_all_str = d15_id.bank.allnum + "/";
                                    if(d15_id.bank.orgnum)
                                        d15_bank_org_str = d15_id.bank.orgnum + "/";
                                    /*
                                    let d15_bank_totalall = 0;
                                    if(d15_id.bank.tra_allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_id.bank.tra_allnum*1;
                                    if(d15_id.bank.ret_allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_id.bank.ret_allnum*1;
                                    if(d15_id.bank.allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_id.bank.allnum*1;
                                    let d15_bank_totalorg = 0;
                                    if(d15_id.bank.tra_orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_id.bank.tra_orgnum*1;
                                    if(d15_id.bank.ret_orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_id.bank.ret_orgnum*1;
                                    if(d15_id.bank.orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_id.bank.orgnum*1;
                                    d15_bank_all_str = d15_bank_totalall + "/";
                                    d15_bank_org_str = d15_bank_totalorg + "/";*/
                                    d15_bank_week_all_str = d15_id.bank.week_allnum + "/";
                                    d15_bank_week_org_str = d15_id.bank.week_orgnum + "/";
                                    d15_bank_night_all_str = d15_id.bank.night_allnum + "/";
                                    d15_bank_night_org_str = d15_id.bank.night_orgnum + "/";
                                }else{
                                    d15_bank_all_str = "-/";
                                    d15_bank_org_str = "-/";
                                    d15_bank_week_all_str = "-/";
                                    d15_bank_week_org_str = "-/";
                                    d15_bank_night_all_str = "-/";
                                    d15_bank_night_org_str = "-/";
                                }
                                if(d15_id.nbank){
                                    if(d15_id.nbank.selfnum)
                                        d15_nbank_self_str = d15_id.nbank.selfnum + "/";
                                    if(d15_id.nbank.allnum)
                                        d15_nbank_all_str = d15_id.nbank.allnum + "/";
                                    if(d15_id.nbank.orgnum)
                                        d15_nbank_org_str = d15_id.nbank.orgnum + "/";
                                    /*
                                    let d15_nbank_totalall = 0;
                                    if(d15_id.nbank.tra_allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_id.nbank.tra_allnum*1;
                                    if(d15_id.nbank.ret_allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_id.nbank.ret_allnum*1;
                                    if(d15_id.nbank.allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_id.nbank.allnum*1;
                                    let d15_nbank_totalorg = 0;
                                    if(d15_id.nbank.tra_orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_id.nbank.tra_orgnum*1;
                                    if(d15_id.nbank.ret_orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_id.nbank.ret_orgnum*1;
                                    if(d15_id.nbank.orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_id.nbank.orgnum*1;
                                    d15_nbank_all_str = d15_nbank_totalall + "/";
                                    d15_nbank_org_str = d15_nbank_totalorg + "/";*/
                                    d15_nbank_week_all_str = d15_id.nbank.week_allnum + "/";
                                    d15_nbank_week_org_str = d15_id.nbank.week_orgnum + "/";
                                    d15_nbank_night_all_str = d15_id.nbank.night_allnum + "/";
                                    d15_nbank_night_org_str = d15_id.nbank.night_orgnum + "/";
                                }else{
                                    d15_nbank_all_str = "-/";
                                    d15_nbank_org_str = "-/";
                                    d15_nbank_week_all_str = "-/";
                                    d15_nbank_week_org_str = "-/";
                                    d15_nbank_night_all_str = "-/";
                                    d15_nbank_night_org_str = "-/";
                                }
                                if(d15_id.caon){
                                    if(d15_id.caon.orgnum)
                                        d15_caon_org_str = d15_id.caon.orgnum + '/';
                                    if(d15_id.caon.allnum)
                                        d15_caon_all_str = d15_id.caon.allnum + '/';
                                }else{
                                    d15_caon_org_str = '-/';
                                    d15_caon_all_str = "-/";
                                }
                                if(d15_id.pdl){
                                    d15_pdl_org_str = d15_id.pdl.orgnum + '/';
                                    d15_pdl_all_str = d15_id.pdl.allnum + '/';
                                }else{
                                    d15_pdl_org_str = '-/';
                                    d15_pdl_all_str = "-/";
                                }
                                if(d15_id.rel){
                                    d15_rel_org_str = d15_id.rel.orgnum + '/';
                                    d15_rel_all_str = d15_id.rel.allnum + '/';
                                }
                                else{
                                    d15_rel_org_str = '-/';
                                    d15_rel_all_str = "-/";
                                }
                                if(d15_id.caoff){
                                    d15_caoff_org_str = d15_id.caoff.orgnum + '/';
                                    d15_caoff_all_str = d15_id.caoff.allnum + '/';
                                }else{
                                    d15_caoff_org_str = '-/';
                                    d15_caoff_all_str = "-/";
                                }
                                if(d15_id.cooff){
                                    d15_cooff_org_str = d15_id.cooff.orgnum + '/';
                                    d15_cooff_all_str = d15_id.cooff.allnum + '/';
                                }else{
                                    d15_cooff_org_str = '-/';
                                    d15_cooff_all_str = "-/";
                                }
                                if(d15_id.af){
                                    d15_af_org_str = d15_id.af.orgnum + '/';
                                    d15_af_all_str = d15_id.af.allnum + '/';
                                }else{
                                    d15_af_org_str = '-/';
                                    d15_af_all_str = "-/";
                                }
                                if(d15_id.coon){
                                    d15_coon_org_str = d15_id.coon.orgnum + '/';
                                    d15_coon_all_str = d15_id.coon.allnum + '/';
                                }else{
                                    d15_coon_org_str = '-/';
                                    d15_coon_all_str = "-/";
                                }
                            }
                            if(d15_cell){
                                if(d15_cell.bank){
                                    /*
                                    let d15_bank_totalall = 0;
                                    if(d15_cell.bank.tra_allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_cell.bank.tra_allnum*1;
                                    if(d15_cell.bank.ret_allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_cell.bank.ret_allnum*1;
                                    if(d15_cell.bank.allnum)
                                        d15_bank_totalall = d15_bank_totalall + d15_cell.bank.allnum*1;
                                    let d15_bank_totalorg = 0;
                                    if(d15_cell.bank.tra_orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_cell.bank.tra_orgnum*1;
                                    if(d15_cell.bank.ret_orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_cell.bank.ret_orgnum*1;
                                    if(d15_cell.bank.orgnum)
                                        d15_bank_totalorg = d15_bank_totalorg + d15_cell.bank.orgnum*1;
                                    d15_bank_all_str = d15_bank_all_str + d15_bank_totalall;
                                    d15_bank_org_str = d15_bank_org_str + d15_bank_totalorg;*/
                                    if(d15_cell.bank.selfnum)
                                        d15_bank_self_str = d15_bank_self_str + d15_cell.bank.selfnum;
                                    if(d15_cell.bank.allnum)
                                        d15_bank_all_str = d15_bank_all_str + d15_cell.bank.allnum;
                                    if(d15_cell.bank.orgnum)
                                        d15_bank_org_str = d15_bank_org_str + d15_cell.bank.orgnum;
                                    d15_bank_week_all_str = d15_bank_week_all_str + d15_cell.bank.week_allnum;
                                    d15_bank_week_org_str = d15_bank_week_org_str + d15_cell.bank.week_orgnum;
                                    d15_bank_night_all_str = d15_bank_night_all_str + d15_cell.bank.night_allnum;
                                    d15_bank_night_org_str = d15_bank_night_org_str + d15_cell.bank.night_orgnum;
                                }else{
                                    d15_bank_all_str = d15_bank_all_str + "-";
                                    d15_bank_org_str = d15_bank_org_str + "-";
                                    d15_bank_week_all_str = d15_bank_week_all_str + "-";
                                    d15_bank_week_org_str = d15_bank_week_org_str + "-";
                                    d15_bank_night_all_str = d15_bank_night_all_str + "-";
                                    d15_bank_night_org_str = d15_bank_night_org_str + "-";
                                }
                                if(d15_cell.nbank){
                                    /*
                                    let d15_nbank_totalall = 0;
                                    if(d15_cell.nbank.tra_allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_cell.nbank.tra_allnum*1;
                                    if(d15_cell.nbank.ret_allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_cell.nbank.ret_allnum*1;
                                    if(d15_cell.nbank.allnum)
                                        d15_nbank_totalall = d15_nbank_totalall + d15_cell.nbank.allnum*1;
                                    let d15_nbank_totalorg = 0;
                                    if(d15_cell.nbank.tra_orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_cell.nbank.tra_orgnum*1;
                                    if(d15_cell.nbank.ret_orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_cell.nbank.ret_orgnum*1;
                                    if(d15_cell.nbank.orgnum)
                                        d15_nbank_totalorg = d15_nbank_totalorg + d15_cell.nbank.orgnum*1;
                                    d15_nbank_all_str = d15_nbank_all_str + d15_nbank_totalall;
                                    d15_nbank_org_str = d15_nbank_org_str + d15_nbank_totalorg;*/
                                    if(d15_cell.nbank.selfnum)
                                        d15_nbank_self_str = d15_nbank_self_str + d15_cell.nbank.selfnum;
                                     if(d15_cell.nbank.allnum)
                                        d15_nbank_all_str = d15_nbank_all_str + d15_cell.nbank.allnum;
                                    if(d15_cell.nbank.orgnum)
                                        d15_nbank_org_str = d15_nbank_org_str + d15_cell.nbank.orgnum;
                                    d15_nbank_week_all_str = d15_nbank_week_all_str + d15_cell.nbank.week_allnum;
                                    d15_nbank_week_org_str = d15_nbank_week_org_str + d15_cell.nbank.week_orgnum;
                                    d15_nbank_night_all_str = d15_nbank_night_all_str + d15_cell.nbank.night_allnum;
                                    d15_nbank_night_org_str = d15_nbank_night_org_str + d15_cell.nbank.night_orgnum;
                                }else{
                                    d15_nbank_all_str = d15_nbank_all_str + "-";
                                    d15_nbank_org_str = d15_nbank_org_str + "-";
                                    d15_nbank_week_all_str = d15_nbank_week_all_str + "-";
                                    d15_nbank_week_org_str = d15_nbank_week_org_str + "-";
                                    d15_nbank_night_all_str = d15_nbank_night_all_str + "-";
                                    d15_nbank_night_org_str = d15_nbank_night_org_str + "-";
                                }
                                if(d15_cell.caon){
                                    if(d15_cell.caon.orgnum)
                                        d15_caon_org_str = d15_caon_org_str + d15_cell.caon.orgnum;
                                    if(d15_cell.caon.allnum)
                                        d15_caon_all_str = d15_caon_all_str + d15_cell.caon.allnum;
                                }else{
                                    d15_caon_org_str = d15_caon_org_str + '-';
                                    d15_caon_all_str = d15_caon_all_str + '-';
                                }
                                if(d15_cell.pdl){
                                    d15_pdl_org_str = d15_pdl_org_str + d15_cell.pdl.orgnum;
                                    d15_pdl_all_str = d15_pdl_all_str + d15_cell.pdl.allnum;
                                }else{
                                    d15_pdl_org_str = d15_pdl_org_str + '-';
                                    d15_pdl_all_str = d15_pdl_all_str + '-';
                                }
                                if(d15_cell.rel){
                                    d15_rel_org_str = d15_rel_org_str + d15_cell.rel.orgnum;
                                    d15_rel_all_str = d15_rel_all_str + d15_cell.rel.allnum;
                                }
                                else{
                                    d15_rel_org_str = d15_rel_org_str + '-';
                                    d15_rel_all_str = d15_rel_all_str + '-';
                                }
                                if(d15_cell.caoff){
                                    d15_caoff_org_str = d15_caoff_org_str + d15_cell.caoff.orgnum;
                                    d15_caoff_all_str = d15_caoff_all_str + d15_cell.caoff.allnum;
                                }else{
                                    d15_caoff_org_str = d15_caoff_org_str + '-';
                                    d15_caoff_all_str = d15_caoff_all_str + '-';
                                }
                                if(d15_cell.cooff){
                                    d15_cooff_org_str = d15_cooff_org_str + d15_cell.cooff.orgnum;
                                    d15_cooff_all_str = d15_cooff_all_str + d15_cell.cooff.allnum;
                                }else{
                                    d15_cooff_org_str = d15_cooff_org_str + '-';
                                    d15_cooff_all_str = d15_cooff_all_str + '-';
                                }
                                if(d15_cell.af){
                                    d15_af_org_str = d15_af_org_str + d15_cell.af.orgnum;
                                    d15_af_all_str = d15_af_all_str + d15_cell.af.allnum;
                                }else{
                                    d15_af_org_str = d15_af_org_str + '-';
                                    d15_af_all_str = d15_af_all_str + '-';
                                }
                                if(d15_cell.coon){
                                    d15_coon_org_str = d15_coon_org_str + d15_cell.coon.orgnum;
                                    d15_coon_all_str = d15_coon_all_str + d15_cell.coon.allnum;
                                }else{
                                    d15_coon_org_str = d15_coon_org_str + '-';
                                    d15_coon_all_str = d15_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!d15_id && !d15_cell){
                                d15_bank_all_str = "-";
                                d15_bank_org_str = "-";
                                d15_bank_week_all_str = "-";
                                d15_bank_week_org_str = "-";
                                d15_bank_night_all_str = "-";
                                d15_bank_night_org_str = "-";
                                d15_nbank_all_str = "-";
                                d15_nbank_org_str = "-";
                                d15_nbank_week_all_str = "-";
                                d15_nbank_week_org_str = "-";
                                d15_nbank_night_all_str = "-";
                                d15_nbank_night_org_str = "-";
                                d15_caon_org_str = '-';
                                d15_caon_all_str = '-';
                                d15_pdl_org_str = '-';
                                d15_pdl_all_str = '-';
                                d15_rel_org_str = '-';
                                d15_rel_all_str = '-';
                                d15_caoff_org_str = '-';
                                d15_caoff_all_str = '-';
                                d15_cooff_org_str = '-';
                                d15_cooff_all_str = '-';
                                d15_af_org_str = '-';
                                d15_af_all_str = '-';
                                d15_coon_org_str = '-';
                                d15_coon_all_str = '-';
                            }
                            bank.d15org = d15_bank_org_str;
                            bank.d15count = d15_bank_all_str;
                            nbank.d15org = d15_nbank_org_str;
                            nbank.d15count = d15_nbank_all_str;
                            bank_night.d15org = d15_bank_night_org_str;
                            bank_night.d15count = d15_bank_night_all_str;
                            nbank_night.d15org = d15_nbank_night_org_str;
                            nbank_night.d15count = d15_nbank_night_all_str;
                            bank_week.d15org = d15_bank_week_org_str;
                            bank_week.d15count = d15_bank_week_all_str;
                            nbank_week.d15org = d15_nbank_week_org_str;
                            nbank_week.d15count = d15_nbank_week_all_str;
                            caon.d15org = d15_caon_org_str;
                            caon.d15count = d15_caon_all_str;
                            pdl.d15org = d15_pdl_org_str;
                            pdl.d15count = d15_pdl_all_str;
                            rel.d15org = d15_rel_org_str;
                            rel.d15count = d15_rel_all_str;
                            caoff.d15org = d15_caoff_org_str;
                            caoff.d15count = d15_caoff_all_str;
                            cooff.d15org = d15_cooff_org_str;
                            cooff.d15count = d15_cooff_all_str;
                            af.d15org = d15_af_org_str;
                            af.d15count = d15_af_all_str;
                            coon.d15org = d15_coon_org_str;
                            coon.d15count = d15_coon_all_str;
                            bank_self.d15 = d15_bank_self_str;
                            nbank_self.d15 = d15_nbank_self_str;
                        }
                        
                        if(key == 'm1'){
                            let m1_id = this.strategy.ApplyLoanStr[key].id;
                            let m1_cell = this.strategy.ApplyLoanStr[key].cell;
                            let m1_caon_org_str = '';
                            let m1_caon_all_str = '';
                            let m1_pdl_org_str = '';
                            let m1_pdl_all_str = '';
                            let m1_rel_org_str = "";
                            let m1_rel_all_str = "";
                            let m1_caoff_all_str = "";
                            let m1_caoff_org_str = "";
                            let m1_cooff_all_str = "";
                            let m1_cooff_org_str = "";
                            let m1_af_all_str = "";
                            let m1_af_org_str = "";
                            let m1_coon_all_str = "";
                            let m1_coon_org_str = "";
                            let m1_bank_all_str = "";
                            let m1_bank_org_str = "";
                            let m1_nbank_all_str = "";
                            let m1_nbank_org_str = "";
                            let m1_bank_week_all_str = "";
                            let m1_bank_week_org_str = "";
                            let m1_nbank_week_all_str = "";
                            let m1_nbank_week_org_str = "";
                            let m1_bank_night_all_str = "";
                            let m1_bank_night_org_str = '';
                            let m1_nbank_night_all_str = "";
                            let m1_nbank_night_org_str = "";
                            let m1_bank_self_str = "";
                            let m1_nbank_self_str = "";
                            if(m1_id){
                                if(m1_id.bank){
                                    if(m1_id.bank.selfnum)
                                        m1_bank_self_str = m1_id.bank.selfnum + "/";
                                    if(m1_id.bank.allnum)
                                        m1_bank_all_str = m1_id.bank.allnum + "/";
                                    if(m1_id.bank.orgnum)
                                        m1_bank_org_str = m1_id.bank.orgnum + "/";
                                    /*
                                    let m1_bank_totalall = 0;
                                    if(m1_id.bank.tra_allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_id.bank.tra_allnum*1;
                                    if(m1_id.bank.ret_allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_id.bank.ret_allnum*1;
                                    if(m1_id.bank.allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_id.bank.allnum*1;
                                    let m1_bank_totalorg = 0;
                                    if(m1_id.bank.tra_orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_id.bank.tra_orgnum*1;
                                    if(m1_id.bank.ret_orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_id.bank.ret_orgnum*1;
                                    if(m1_id.bank.orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_id.bank.orgnum*1;
                                    m1_bank_all_str = m1_bank_totalall + "/";
                                    m1_bank_org_str = m1_bank_totalorg + "/";*/
                                    m1_bank_week_all_str = m1_id.bank.week_allnum + "/";
                                    m1_bank_week_org_str = m1_id.bank.week_orgnum + "/";
                                    m1_bank_night_all_str = m1_id.bank.night_allnum + "/";
                                    m1_bank_night_org_str = m1_id.bank.night_orgnum + "/";
                                }else{
                                    m1_bank_all_str = "-/";
                                    m1_bank_org_str = "-/";
                                    m1_bank_week_all_str = "-/";
                                    m1_bank_week_org_str = "-/";
                                    m1_bank_night_all_str = "-/";
                                    m1_bank_night_org_str = "-/";
                                }
                                if(m1_id.nbank){
                                    if(m1_id.nbank.selfnum)
                                        m1_nbank_self_str = m1_id.nbank.selfnum + "/";
                                    if(m1_id.nbank.allnum)
                                        m1_nbank_all_str = m1_id.nbank.allnum + "/";
                                    if(m1_id.nbank.orgnum)
                                        m1_nbank_org_str = m1_id.nbank.orgnum + "/";
                                    /*
                                    let m1_nbank_totalall = 0;
                                    if(m1_id.nbank.tra_allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_id.nbank.tra_allnum*1;
                                    if(m1_id.nbank.ret_allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_id.nbank.ret_allnum*1;
                                    if(m1_id.nbank.allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_id.nbank.allnum*1;
                                    let m1_nbank_totalorg = 0;
                                    if(m1_id.nbank.tra_orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_id.nbank.tra_orgnum*1;
                                    if(m1_id.nbank.ret_orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_id.nbank.ret_orgnum*1;
                                    if(m1_id.nbank.orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_id.nbank.orgnum*1;
                                    m1_nbank_all_str = m1_nbank_totalall + "/";
                                    m1_nbank_org_str = m1_nbank_totalorg + "/";*/
                                    m1_nbank_week_all_str = m1_id.nbank.week_allnum + "/";
                                    m1_nbank_week_org_str = m1_id.nbank.week_orgnum + "/";
                                    m1_nbank_night_all_str = m1_id.nbank.night_allnum + "/";
                                    m1_nbank_night_org_str = m1_id.nbank.night_orgnum + "/";
                                }else{
                                    m1_nbank_all_str = "-/";
                                    m1_nbank_org_str = "-/";
                                    m1_nbank_week_all_str = "-/";
                                    m1_nbank_week_org_str = "-/";
                                    m1_nbank_night_all_str = "-/";
                                    m1_nbank_night_org_str = "-/";
                                }
                                if(m1_id.caon){
                                    if(m1_id.caon.orgnum)
                                        m1_caon_org_str = m1_id.caon.orgnum + '/';
                                    if(m1_id.caon.allnum)
                                        m1_caon_all_str = m1_id.caon.allnum + '/';
                                }else{
                                    m1_caon_org_str = '-/';
                                    m1_caon_all_str = "-/";
                                }
                                if(m1_id.pdl){
                                    m1_pdl_org_str = m1_id.pdl.orgnum + '/';
                                    m1_pdl_all_str = m1_id.pdl.allnum + '/';
                                }else{
                                    m1_pdl_org_str = '-/';
                                    m1_pdl_all_str = "-/";
                                }
                                if(m1_id.rel){
                                    m1_rel_org_str = m1_id.rel.orgnum + '/';
                                    m1_rel_all_str = m1_id.rel.allnum + '/';
                                }
                                else{
                                    m1_rel_org_str = '-/';
                                    m1_rel_all_str = "-/";
                                }
                                if(m1_id.caoff){
                                    m1_caoff_org_str = m1_id.caoff.orgnum + '/';
                                    m1_caoff_all_str = m1_id.caoff.allnum + '/';
                                }else{
                                    m1_caoff_org_str = '-/';
                                    m1_caoff_all_str = "-/";
                                }
                                if(m1_id.cooff){
                                    m1_cooff_org_str = m1_id.cooff.orgnum + '/';
                                    m1_cooff_all_str = m1_id.cooff.allnum + '/';
                                }else{
                                    m1_cooff_org_str = '-/';
                                    m1_cooff_all_str = "-/";
                                }
                                if(m1_id.af){
                                    m1_af_org_str = m1_id.af.orgnum + '/';
                                    m1_af_all_str = m1_id.af.allnum + '/';
                                }else{
                                    m1_af_org_str = '-/';
                                    m1_af_all_str = "-/";
                                }
                                if(m1_id.coon){
                                    m1_coon_org_str = m1_id.coon.orgnum + '/';
                                    m1_coon_all_str = m1_id.coon.allnum + '/';
                                }else{
                                    m1_coon_org_str = '-/';
                                    m1_coon_all_str = "-/";
                                }
                            }
                            if(m1_cell){
                                if(m1_cell.bank){
                                    /*
                                    let m1_bank_totalall = 0;
                                    if(m1_cell.bank.tra_allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_cell.bank.tra_allnum*1;
                                    if(m1_cell.bank.ret_allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_cell.bank.ret_allnum*1;
                                    if(m1_cell.bank.allnum)
                                        m1_bank_totalall = m1_bank_totalall + m1_cell.bank.allnum*1;
                                    let m1_bank_totalorg = 0;
                                    if(m1_cell.bank.tra_orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_cell.bank.tra_orgnum*1;
                                    if(m1_cell.bank.ret_orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_cell.bank.ret_orgnum*1;
                                    if(m1_cell.bank.orgnum)
                                        m1_bank_totalorg = m1_bank_totalorg + m1_cell.bank.orgnum*1;
                                    m1_bank_all_str = m1_bank_all_str + m1_bank_totalall;
                                    m1_bank_org_str = m1_bank_org_str + m1_bank_totalorg;*/
                                    if(m1_cell.bank.selfnum)
                                        m1_bank_self_str = m1_bank_self_str + m1_cell.bank.selfnum;
                                    if(m1_cell.bank.allnum)
                                        m1_bank_all_str = m1_bank_all_str + m1_cell.bank.allnum;
                                    if(m1_cell.bank.orgnum)
                                        m1_bank_org_str = m1_bank_org_str + m1_cell.bank.orgnum;
                                    m1_bank_week_all_str = m1_bank_week_all_str + m1_cell.bank.week_allnum;
                                    m1_bank_week_org_str = m1_bank_week_org_str + m1_cell.bank.week_orgnum;
                                    m1_bank_night_all_str = m1_bank_night_all_str + m1_cell.bank.night_allnum;
                                    m1_bank_night_org_str = m1_bank_night_org_str + m1_cell.bank.night_orgnum;
                                }else{
                                    m1_bank_all_str = m1_bank_all_str + "-";
                                    m1_bank_org_str = m1_bank_org_str + "-";
                                    m1_bank_week_all_str = m1_bank_week_all_str + "-";
                                    m1_bank_week_org_str = m1_bank_week_org_str + "-";
                                    m1_bank_night_all_str = m1_bank_night_all_str + "-";
                                    m1_bank_night_org_str = m1_bank_night_org_str + "-";
                                }
                                if(m1_cell.nbank){
                                    /*
                                    let m1_nbank_totalall = 0;
                                    if(m1_cell.nbank.tra_allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_cell.nbank.tra_allnum*1;
                                    if(m1_cell.nbank.ret_allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_cell.nbank.ret_allnum*1;
                                    if(m1_cell.nbank.allnum)
                                        m1_nbank_totalall = m1_nbank_totalall + m1_cell.nbank.allnum*1;
                                    let m1_nbank_totalorg = 0;
                                    if(m1_cell.nbank.tra_orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_cell.nbank.tra_orgnum*1;
                                    if(m1_cell.nbank.ret_orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_cell.nbank.ret_orgnum*1;
                                    if(m1_cell.nbank.orgnum)
                                        m1_nbank_totalorg = m1_nbank_totalorg + m1_cell.nbank.orgnum*1;
                                    m1_nbank_all_str = m1_nbank_all_str + m1_nbank_totalall;
                                    m1_nbank_org_str = m1_nbank_org_str + m1_nbank_totalorg;*/
                                    if(m1_cell.nbank.selfnum)
                                        m1_nbank_self_str = m1_nbank_self_str + m1_cell.nbank.selfnum;
                                     if(m1_cell.nbank.allnum)
                                        m1_nbank_all_str = m1_nbank_all_str + m1_cell.nbank.allnum;
                                    if(m1_cell.nbank.orgnum)
                                        m1_nbank_org_str = m1_nbank_org_str + m1_cell.nbank.orgnum;
                                    m1_nbank_week_all_str = m1_nbank_week_all_str + m1_cell.nbank.week_allnum;
                                    m1_nbank_week_org_str = m1_nbank_week_org_str + m1_cell.nbank.week_orgnum;
                                    m1_nbank_night_all_str = m1_nbank_night_all_str + m1_cell.nbank.night_allnum;
                                    m1_nbank_night_org_str = m1_nbank_night_org_str + m1_cell.nbank.night_orgnum;
                                }else{
                                    m1_nbank_all_str = m1_nbank_all_str + "-";
                                    m1_nbank_org_str = m1_nbank_org_str + "-";
                                    m1_nbank_week_all_str = m1_nbank_week_all_str + "-";
                                    m1_nbank_week_org_str = m1_nbank_week_org_str + "-";
                                    m1_nbank_night_all_str = m1_nbank_night_all_str + "-";
                                    m1_nbank_night_org_str = m1_nbank_night_org_str + "-";
                                }
                                if(m1_cell.caon){
                                    if(m1_cell.caon.orgnum)
                                        m1_caon_org_str = m1_caon_org_str + m1_cell.caon.orgnum;
                                    if(m1_cell.caon.allnum)
                                        m1_caon_all_str = m1_caon_all_str + m1_cell.caon.allnum;
                                }else{
                                    m1_caon_org_str = m1_caon_org_str + '-';
                                    m1_caon_all_str = m1_caon_all_str + '-';
                                }
                                if(m1_cell.pdl){
                                    m1_pdl_org_str = m1_pdl_org_str + m1_cell.pdl.orgnum;
                                    m1_pdl_all_str = m1_pdl_all_str + m1_cell.pdl.allnum;
                                }else{
                                    m1_pdl_org_str = m1_pdl_org_str + '-';
                                    m1_pdl_all_str = m1_pdl_all_str + '-';
                                }
                                if(m1_cell.rel){
                                    m1_rel_org_str = m1_rel_org_str + m1_cell.rel.orgnum;
                                    m1_rel_all_str = m1_rel_all_str + m1_cell.rel.allnum;
                                }
                                else{
                                    m1_rel_org_str = m1_rel_org_str + '-';
                                    m1_rel_all_str = m1_rel_all_str + '-';
                                }
                                if(m1_cell.caoff){
                                    m1_caoff_org_str = m1_caoff_org_str + m1_cell.caoff.orgnum;
                                    m1_caoff_all_str = m1_caoff_all_str + m1_cell.caoff.allnum;
                                }else{
                                    m1_caoff_org_str = m1_caoff_org_str + '-';
                                    m1_caoff_all_str = m1_caoff_all_str + '-';
                                }
                                if(m1_cell.cooff){
                                    m1_cooff_org_str = m1_cooff_org_str + m1_cell.cooff.orgnum;
                                    m1_cooff_all_str = m1_cooff_all_str + m1_cell.cooff.allnum;
                                }else{
                                    m1_cooff_org_str = m1_cooff_org_str + '-';
                                    m1_cooff_all_str = m1_cooff_all_str + '-';
                                }
                                if(m1_cell.af){
                                    m1_af_org_str = m1_af_org_str + m1_cell.af.orgnum;
                                    m1_af_all_str = m1_af_all_str + m1_cell.af.allnum;
                                }else{
                                    m1_af_org_str = m1_af_org_str + '-';
                                    m1_af_all_str = m1_af_all_str + '-';
                                }
                                if(m1_cell.coon){
                                    m1_coon_org_str = m1_coon_org_str + m1_cell.coon.orgnum;
                                    m1_coon_all_str = m1_coon_all_str + m1_cell.coon.allnum;
                                }else{
                                    m1_coon_org_str = m1_coon_org_str + '-';
                                    m1_coon_all_str = m1_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!m1_id && !m1_cell){
                                m1_bank_all_str = "-";
                                m1_bank_org_str = "-";
                                m1_bank_week_all_str = "-";
                                m1_bank_week_org_str = "-";
                                m1_bank_night_all_str = "-";
                                m1_bank_night_org_str = "-";
                                m1_nbank_all_str = "-";
                                m1_nbank_org_str = "-";
                                m1_nbank_week_all_str = "-";
                                m1_nbank_week_org_str = "-";
                                m1_nbank_night_all_str = "-";
                                m1_nbank_night_org_str = "-";
                                m1_caon_org_str = '-';
                                m1_caon_all_str = '-';
                                m1_pdl_org_str = '-';
                                m1_pdl_all_str = '-';
                                m1_rel_org_str = '-';
                                m1_rel_all_str = '-';
                                m1_caoff_org_str = '-';
                                m1_caoff_all_str = '-';
                                m1_cooff_org_str = '-';
                                m1_cooff_all_str = '-';
                                m1_af_org_str = '-';
                                m1_af_all_str = '-';
                                m1_coon_org_str = '-';
                                m1_coon_all_str = '-';
                            }
                            bank.m1org = m1_bank_org_str;
                            bank.m1count = m1_bank_all_str;
                            nbank.m1org = m1_nbank_org_str;
                            nbank.m1count = m1_nbank_all_str;
                            bank_night.m1org = m1_bank_night_org_str;
                            bank_night.m1count = m1_bank_night_all_str;
                            nbank_night.m1org = m1_nbank_night_org_str;
                            nbank_night.m1count = m1_nbank_night_all_str;
                            bank_week.m1org = m1_bank_week_org_str;
                            bank_week.m1count = m1_bank_week_all_str;
                            nbank_week.m1org = m1_nbank_week_org_str;
                            nbank_week.m1count = m1_nbank_week_all_str;
                            caon.m1org = m1_caon_org_str;
                            caon.m1count = m1_caon_all_str;
                            pdl.m1org = m1_pdl_org_str;
                            pdl.m1count = m1_pdl_all_str;
                            rel.m1org = m1_rel_org_str;
                            rel.m1count = m1_rel_all_str;
                            caoff.m1org = m1_caoff_org_str;
                            caoff.m1count = m1_caoff_all_str;
                            cooff.m1org = m1_cooff_org_str;
                            cooff.m1count = m1_cooff_all_str;
                            af.m1org = m1_af_org_str;
                            af.m1count = m1_af_all_str;
                            coon.m1org = m1_coon_org_str;
                            coon.m1count = m1_coon_all_str;
                            bank_self.m1 = m1_bank_self_str;
                            nbank_self.m1 = m1_nbank_self_str;
                        }
                        if(key == 'm3'){
                            let m3_id = this.strategy.ApplyLoanStr[key].id;
                            let m3_cell = this.strategy.ApplyLoanStr[key].cell;
                            let m3_caon_org_str = '';
                            let m3_caon_all_str = '';
                            let m3_pdl_org_str = '';
                            let m3_pdl_all_str = '';
                            let m3_rel_org_str = "";
                            let m3_rel_all_str = "";
                            let m3_caoff_all_str = "";
                            let m3_caoff_org_str = "";
                            let m3_cooff_all_str = "";
                            let m3_cooff_org_str = "";
                            let m3_af_all_str = "";
                            let m3_af_org_str = "";
                            let m3_coon_all_str = "";
                            let m3_coon_org_str = "";
                            let m3_bank_all_str = "";
                            let m3_bank_org_str = "";
                            let m3_nbank_all_str = "";
                            let m3_nbank_org_str = "";
                            let m3_bank_week_all_str = "";
                            let m3_bank_week_org_str = "";
                            let m3_nbank_week_all_str = "";
                            let m3_nbank_week_org_str = "";
                            let m3_bank_night_all_str = "";
                            let m3_bank_night_org_str = '';
                            let m3_nbank_night_all_str = "";
                            let m3_nbank_night_org_str = "";
                            let m3_bank_self_str = "";
                            let m3_nbank_self_str = "";
                            if(m3_id){
                                if(m3_id.bank){
                                    if(m3_id.bank.selfnum)
                                        m3_bank_self_str = m3_id.bank.selfnum + "/";
                                    if(m3_id.bank.allnum)
                                        m3_bank_all_str = m3_id.bank.allnum + "/";
                                    if(m3_id.bank.orgnum)
                                        m3_bank_org_str = m3_id.bank.orgnum + "/";
                                    /*
                                    let m3_bank_totalall = 0;
                                    if(m3_id.bank.tra_allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_id.bank.tra_allnum*1;
                                    if(m3_id.bank.ret_allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_id.bank.ret_allnum*1;
                                    if(m3_id.bank.allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_id.bank.allnum*1;
                                    let m3_bank_totalorg = 0;
                                    if(m3_id.bank.tra_orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_id.bank.tra_orgnum*1;
                                    if(m3_id.bank.ret_orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_id.bank.ret_orgnum*1;
                                    if(m3_id.bank.orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_id.bank.orgnum*1;
                                    m3_bank_all_str = m3_bank_totalall + "/";
                                    m3_bank_org_str = m3_bank_totalorg + "/";*/
                                    m3_bank_week_all_str = m3_id.bank.week_allnum + "/";
                                    m3_bank_week_org_str = m3_id.bank.week_orgnum + "/";
                                    m3_bank_night_all_str = m3_id.bank.night_allnum + "/";
                                    m3_bank_night_org_str = m3_id.bank.night_orgnum + "/";
                                }else{
                                    m3_bank_all_str = "-/";
                                    m3_bank_org_str = "-/";
                                    m3_bank_week_all_str = "-/";
                                    m3_bank_week_org_str = "-/";
                                    m3_bank_night_all_str = "-/";
                                    m3_bank_night_org_str = "-/";
                                }
                                if(m3_id.nbank){
                                    if(m3_id.nbank.selfnum)
                                        m3_nbank_self_str = m3_id.nbank.selfnum + "/";
                                    if(m3_id.nbank.allnum)
                                        m3_nbank_all_str = m3_id.nbank.allnum + "/";
                                    if(m3_id.nbank.orgnum)
                                        m3_nbank_org_str = m3_id.nbank.orgnum + "/";
                                    /*
                                    let m3_nbank_totalall = 0;
                                    if(m3_id.nbank.tra_allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_id.nbank.tra_allnum*1;
                                    if(m3_id.nbank.ret_allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_id.nbank.ret_allnum*1;
                                    if(m3_id.nbank.allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_id.nbank.allnum*1;
                                    let m3_nbank_totalorg = 0;
                                    if(m3_id.nbank.tra_orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_id.nbank.tra_orgnum*1;
                                    if(m3_id.nbank.ret_orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_id.nbank.ret_orgnum*1;
                                    if(m3_id.nbank.orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_id.nbank.orgnum*1;
                                    m3_nbank_all_str = m3_nbank_totalall + "/";
                                    m3_nbank_org_str = m3_nbank_totalorg + "/";*/
                                    m3_nbank_week_all_str = m3_id.nbank.week_allnum + "/";
                                    m3_nbank_week_org_str = m3_id.nbank.week_orgnum + "/";
                                    m3_nbank_night_all_str = m3_id.nbank.night_allnum + "/";
                                    m3_nbank_night_org_str = m3_id.nbank.night_orgnum + "/";
                                }else{
                                    m3_nbank_all_str = "-/";
                                    m3_nbank_org_str = "-/";
                                    m3_nbank_week_all_str = "-/";
                                    m3_nbank_week_org_str = "-/";
                                    m3_nbank_night_all_str = "-/";
                                    m3_nbank_night_org_str = "-/";
                                }
                                if(m3_id.caon){
                                    if(m3_id.caon.orgnum)
                                        m3_caon_org_str = m3_id.caon.orgnum + '/';
                                    if(m3_id.caon.allnum)
                                        m3_caon_all_str = m3_id.caon.allnum + '/';
                                }else{
                                    m3_caon_org_str = '-/';
                                    m3_caon_all_str = "-/";
                                }
                                if(m3_id.pdl){
                                    m3_pdl_org_str = m3_id.pdl.orgnum + '/';
                                    m3_pdl_all_str = m3_id.pdl.allnum + '/';
                                }else{
                                    m3_pdl_org_str = '-/';
                                    m3_pdl_all_str = "-/";
                                }
                                if(m3_id.rel){
                                    m3_rel_org_str = m3_id.rel.orgnum + '/';
                                    m3_rel_all_str = m3_id.rel.allnum + '/';
                                }
                                else{
                                    m3_rel_org_str = '-/';
                                    m3_rel_all_str = "-/";
                                }
                                if(m3_id.caoff){
                                    m3_caoff_org_str = m3_id.caoff.orgnum + '/';
                                    m3_caoff_all_str = m3_id.caoff.allnum + '/';
                                }else{
                                    m3_caoff_org_str = '-/';
                                    m3_caoff_all_str = "-/";
                                }
                                if(m3_id.cooff){
                                    m3_cooff_org_str = m3_id.cooff.orgnum + '/';
                                    m3_cooff_all_str = m3_id.cooff.allnum + '/';
                                }else{
                                    m3_cooff_org_str = '-/';
                                    m3_cooff_all_str = "-/";
                                }
                                if(m3_id.af){
                                    m3_af_org_str = m3_id.af.orgnum + '/';
                                    m3_af_all_str = m3_id.af.allnum + '/';
                                }else{
                                    m3_af_org_str = '-/';
                                    m3_af_all_str = "-/";
                                }
                                if(m3_id.coon){
                                    m3_coon_org_str = m3_id.coon.orgnum + '/';
                                    m3_coon_all_str = m3_id.coon.allnum + '/';
                                }else{
                                    m3_coon_org_str = '-/';
                                    m3_coon_all_str = "-/";
                                }
                            }
                            if(m3_cell){
                                if(m3_cell.bank){
                                    /*
                                    let m3_bank_totalall = 0;
                                    if(m3_cell.bank.tra_allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_cell.bank.tra_allnum*1;
                                    if(m3_cell.bank.ret_allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_cell.bank.ret_allnum*1;
                                    if(m3_cell.bank.allnum)
                                        m3_bank_totalall = m3_bank_totalall + m3_cell.bank.allnum*1;
                                    let m3_bank_totalorg = 0;
                                    if(m3_cell.bank.tra_orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_cell.bank.tra_orgnum*1;
                                    if(m3_cell.bank.ret_orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_cell.bank.ret_orgnum*1;
                                    if(m3_cell.bank.orgnum)
                                        m3_bank_totalorg = m3_bank_totalorg + m3_cell.bank.orgnum*1;
                                    m3_bank_all_str = m3_bank_all_str + m3_bank_totalall;
                                    m3_bank_org_str = m3_bank_org_str + m3_bank_totalorg;*/
                                    if(m3_cell.bank.selfnum)
                                        m3_bank_self_str = m3_bank_self_str + m3_cell.bank.selfnum;
                                    if(m3_cell.bank.allnum)
                                        m3_bank_all_str = m3_bank_all_str + m3_cell.bank.allnum;
                                    if(m3_cell.bank.orgnum)
                                        m3_bank_org_str = m3_bank_org_str + m3_cell.bank.orgnum;
                                    m3_bank_week_all_str = m3_bank_week_all_str + m3_cell.bank.week_allnum;
                                    m3_bank_week_org_str = m3_bank_week_org_str + m3_cell.bank.week_orgnum;
                                    m3_bank_night_all_str = m3_bank_night_all_str + m3_cell.bank.night_allnum;
                                    m3_bank_night_org_str = m3_bank_night_org_str + m3_cell.bank.night_orgnum;
                                }else{
                                    m3_bank_all_str = m3_bank_all_str + "-";
                                    m3_bank_org_str = m3_bank_org_str + "-";
                                    m3_bank_week_all_str = m3_bank_week_all_str + "-";
                                    m3_bank_week_org_str = m3_bank_week_org_str + "-";
                                    m3_bank_night_all_str = m3_bank_night_all_str + "-";
                                    m3_bank_night_org_str = m3_bank_night_org_str + "-";
                                }
                                if(m3_cell.nbank){
                                    /*
                                    let m3_nbank_totalall = 0;
                                    if(m3_cell.nbank.tra_allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_cell.nbank.tra_allnum*1;
                                    if(m3_cell.nbank.ret_allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_cell.nbank.ret_allnum*1;
                                    if(m3_cell.nbank.allnum)
                                        m3_nbank_totalall = m3_nbank_totalall + m3_cell.nbank.allnum*1;
                                    let m3_nbank_totalorg = 0;
                                    if(m3_cell.nbank.tra_orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_cell.nbank.tra_orgnum*1;
                                    if(m3_cell.nbank.ret_orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_cell.nbank.ret_orgnum*1;
                                    if(m3_cell.nbank.orgnum)
                                        m3_nbank_totalorg = m3_nbank_totalorg + m3_cell.nbank.orgnum*1;
                                    m3_nbank_all_str = m3_nbank_all_str + m3_nbank_totalall;
                                    m3_nbank_org_str = m3_nbank_org_str + m3_nbank_totalorg;*/
                                    if(m3_cell.nbank.selfnum)
                                        m3_nbank_self_str = m3_nbank_self_str + m3_cell.nbank.selfnum;
                                     if(m3_cell.nbank.allnum)
                                        m3_nbank_all_str = m3_nbank_all_str + m3_cell.nbank.allnum;
                                    if(m3_cell.nbank.orgnum)
                                        m3_nbank_org_str = m3_nbank_org_str + m3_cell.nbank.orgnum;
                                    m3_nbank_week_all_str = m3_nbank_week_all_str + m3_cell.nbank.week_allnum;
                                    m3_nbank_week_org_str = m3_nbank_week_org_str + m3_cell.nbank.week_orgnum;
                                    m3_nbank_night_all_str = m3_nbank_night_all_str + m3_cell.nbank.night_allnum;
                                    m3_nbank_night_org_str = m3_nbank_night_org_str + m3_cell.nbank.night_orgnum;
                                }else{
                                    m3_nbank_all_str = m3_nbank_all_str + "-";
                                    m3_nbank_org_str = m3_nbank_org_str + "-";
                                    m3_nbank_week_all_str = m3_nbank_week_all_str + "-";
                                    m3_nbank_week_org_str = m3_nbank_week_org_str + "-";
                                    m3_nbank_night_all_str = m3_nbank_night_all_str + "-";
                                    m3_nbank_night_org_str = m3_nbank_night_org_str + "-";
                                }
                                if(m3_cell.caon){
                                    if(m3_cell.caon.orgnum)
                                        m3_caon_org_str = m3_caon_org_str + m3_cell.caon.orgnum;
                                    if(m3_cell.caon.allnum)
                                        m3_caon_all_str = m3_caon_all_str + m3_cell.caon.allnum;
                                }else{
                                    m3_caon_org_str = m3_caon_org_str + '-';
                                    m3_caon_all_str = m3_caon_all_str + '-';
                                }
                                if(m3_cell.pdl){
                                    m3_pdl_org_str = m3_pdl_org_str + m3_cell.pdl.orgnum;
                                    m3_pdl_all_str = m3_pdl_all_str + m3_cell.pdl.allnum;
                                }else{
                                    m3_pdl_org_str = m3_pdl_org_str + '-';
                                    m3_pdl_all_str = m3_pdl_all_str + '-';
                                }
                                if(m3_cell.rel){
                                    m3_rel_org_str = m3_rel_org_str + m3_cell.rel.orgnum;
                                    m3_rel_all_str = m3_rel_all_str + m3_cell.rel.allnum;
                                }
                                else{
                                    m3_rel_org_str = m3_rel_org_str + '-';
                                    m3_rel_all_str = m3_rel_all_str + '-';
                                }
                                if(m3_cell.caoff){
                                    m3_caoff_org_str = m3_caoff_org_str + m3_cell.caoff.orgnum;
                                    m3_caoff_all_str = m3_caoff_all_str + m3_cell.caoff.allnum;
                                }else{
                                    m3_caoff_org_str = m3_caoff_org_str + '-';
                                    m3_caoff_all_str = m3_caoff_all_str + '-';
                                }
                                if(m3_cell.cooff){
                                    m3_cooff_org_str = m3_cooff_org_str + m3_cell.cooff.orgnum;
                                    m3_cooff_all_str = m3_cooff_all_str + m3_cell.cooff.allnum;
                                }else{
                                    m3_cooff_org_str = m3_cooff_org_str + '-';
                                    m3_cooff_all_str = m3_cooff_all_str + '-';
                                }
                                if(m3_cell.af){
                                    m3_af_org_str = m3_af_org_str + m3_cell.af.orgnum;
                                    m3_af_all_str = m3_af_all_str + m3_cell.af.allnum;
                                }else{
                                    m3_af_org_str = m3_af_org_str + '-';
                                    m3_af_all_str = m3_af_all_str + '-';
                                }
                                if(m3_cell.coon){
                                    m3_coon_org_str = m3_coon_org_str + m3_cell.coon.orgnum;
                                    m3_coon_all_str = m3_coon_all_str + m3_cell.coon.allnum;
                                }else{
                                    m3_coon_org_str = m3_coon_org_str + '-';
                                    m3_coon_all_str = m3_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!m3_id && !m3_cell){
                                m3_bank_all_str = "-";
                                m3_bank_org_str = "-";
                                m3_bank_week_all_str = "-";
                                m3_bank_week_org_str = "-";
                                m3_bank_night_all_str = "-";
                                m3_bank_night_org_str = "-";
                                m3_nbank_all_str = "-";
                                m3_nbank_org_str = "-";
                                m3_nbank_week_all_str = "-";
                                m3_nbank_week_org_str = "-";
                                m3_nbank_night_all_str = "-";
                                m3_nbank_night_org_str = "-";
                                m3_caon_org_str = '-';
                                m3_caon_all_str = '-';
                                m3_pdl_org_str = '-';
                                m3_pdl_all_str = '-';
                                m3_rel_org_str = '-';
                                m3_rel_all_str = '-';
                                m3_caoff_org_str = '-';
                                m3_caoff_all_str = '-';
                                m3_cooff_org_str = '-';
                                m3_cooff_all_str = '-';
                                m3_af_org_str = '-';
                                m3_af_all_str = '-';
                                m3_coon_org_str = '-';
                                m3_coon_all_str = '-';
                            }
                            bank.m3org = m3_bank_org_str;
                            bank.m3count = m3_bank_all_str;
                            nbank.m3org = m3_nbank_org_str;
                            nbank.m3count = m3_nbank_all_str;
                            bank_night.m3org = m3_bank_night_org_str;
                            bank_night.m3count = m3_bank_night_all_str;
                            nbank_night.m3org = m3_nbank_night_org_str;
                            nbank_night.m3count = m3_nbank_night_all_str;
                            bank_week.m3org = m3_bank_week_org_str;
                            bank_week.m3count = m3_bank_week_all_str;
                            nbank_week.m3org = m3_nbank_week_org_str;
                            nbank_week.m3count = m3_nbank_week_all_str;
                            caon.m3org = m3_caon_org_str;
                            caon.m3count = m3_caon_all_str;
                            pdl.m3org = m3_pdl_org_str;
                            pdl.m3count = m3_pdl_all_str;
                            rel.m3org = m3_rel_org_str;
                            rel.m3count = m3_rel_all_str;
                            caoff.m3org = m3_caoff_org_str;
                            caoff.m3count = m3_caoff_all_str;
                            cooff.m3org = m3_cooff_org_str;
                            cooff.m3count = m3_cooff_all_str;
                            af.m3org = m3_af_org_str;
                            af.m3count = m3_af_all_str;
                            coon.m3org = m3_coon_org_str;
                            coon.m3count = m3_coon_all_str;
                            bank_self.m3 = m3_bank_self_str;
                            nbank_self.m3 = m3_nbank_self_str;
                        }
                        if(key == 'm6'){
                            let m6_id = this.strategy.ApplyLoanStr[key].id;
                            let m6_cell = this.strategy.ApplyLoanStr[key].cell;
                            let m6_caon_org_str = '';
                            let m6_caon_all_str = '';
                            let m6_pdl_org_str = '';
                            let m6_pdl_all_str = '';
                            let m6_rel_org_str = "";
                            let m6_rel_all_str = "";
                            let m6_caoff_all_str = "";
                            let m6_caoff_org_str = "";
                            let m6_cooff_all_str = "";
                            let m6_cooff_org_str = "";
                            let m6_af_all_str = "";
                            let m6_af_org_str = "";
                            let m6_coon_all_str = "";
                            let m6_coon_org_str = "";
                            let m6_bank_all_str = "";
                            let m6_bank_org_str = "";
                            let m6_nbank_all_str = "";
                            let m6_nbank_org_str = "";
                            let m6_bank_week_all_str = "";
                            let m6_bank_week_org_str = "";
                            let m6_nbank_week_all_str = "";
                            let m6_nbank_week_org_str = "";
                            let m6_bank_night_all_str = "";
                            let m6_bank_night_org_str = '';
                            let m6_nbank_night_all_str = "";
                            let m6_nbank_night_org_str = "";
                            let m6_bank_self_str = "";
                            let m6_nbank_self_str = "";
                            if(m6_id){
                                if(m6_id.bank){
                                    if(m6_id.bank.selfnum)
                                        m6_bank_self_str = m6_id.bank.selfnum + "/";
                                    if(m6_id.bank.allnum)
                                        m6_bank_all_str = m6_id.bank.allnum + "/";
                                    if(m6_id.bank.orgnum)
                                        m6_bank_org_str = m6_id.bank.orgnum + "/";
                                    /*
                                    let m6_bank_totalall = 0;
                                    if(m6_id.bank.tra_allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_id.bank.tra_allnum*1;
                                    if(m6_id.bank.ret_allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_id.bank.ret_allnum*1;
                                    if(m6_id.bank.allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_id.bank.allnum*1;
                                    let m6_bank_totalorg = 0;
                                    if(m6_id.bank.tra_orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_id.bank.tra_orgnum*1;
                                    if(m6_id.bank.ret_orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_id.bank.ret_orgnum*1;
                                    if(m6_id.bank.orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_id.bank.orgnum*1;
                                    m6_bank_all_str = m6_bank_totalall + "/";
                                    m6_bank_org_str = m6_bank_totalorg + "/";*/
                                    m6_bank_week_all_str = m6_id.bank.week_allnum + "/";
                                    m6_bank_week_org_str = m6_id.bank.week_orgnum + "/";
                                    m6_bank_night_all_str = m6_id.bank.night_allnum + "/";
                                    m6_bank_night_org_str = m6_id.bank.night_orgnum + "/";
                                }else{
                                    m6_bank_all_str = "-/";
                                    m6_bank_org_str = "-/";
                                    m6_bank_week_all_str = "-/";
                                    m6_bank_week_org_str = "-/";
                                    m6_bank_night_all_str = "-/";
                                    m6_bank_night_org_str = "-/";
                                }
                                if(m6_id.nbank){
                                    if(m6_id.nbank.selfnum)
                                        m6_nbank_self_str = m6_id.nbank.selfnum + "/";
                                    if(m6_id.nbank.allnum)
                                        m6_nbank_all_str = m6_id.nbank.allnum + "/";
                                    if(m6_id.nbank.orgnum)
                                        m6_nbank_org_str = m6_id.nbank.orgnum + "/";
                                    /*
                                    let m6_nbank_totalall = 0;
                                    if(m6_id.nbank.tra_allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_id.nbank.tra_allnum*1;
                                    if(m6_id.nbank.ret_allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_id.nbank.ret_allnum*1;
                                    if(m6_id.nbank.allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_id.nbank.allnum*1;
                                    let m6_nbank_totalorg = 0;
                                    if(m6_id.nbank.tra_orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_id.nbank.tra_orgnum*1;
                                    if(m6_id.nbank.ret_orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_id.nbank.ret_orgnum*1;
                                    if(m6_id.nbank.orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_id.nbank.orgnum*1;
                                    m6_nbank_all_str = m6_nbank_totalall + "/";
                                    m6_nbank_org_str = m6_nbank_totalorg + "/";*/
                                    m6_nbank_week_all_str = m6_id.nbank.week_allnum + "/";
                                    m6_nbank_week_org_str = m6_id.nbank.week_orgnum + "/";
                                    m6_nbank_night_all_str = m6_id.nbank.night_allnum + "/";
                                    m6_nbank_night_org_str = m6_id.nbank.night_orgnum + "/";
                                }else{
                                    m6_nbank_all_str = "-/";
                                    m6_nbank_org_str = "-/";
                                    m6_nbank_week_all_str = "-/";
                                    m6_nbank_week_org_str = "-/";
                                    m6_nbank_night_all_str = "-/";
                                    m6_nbank_night_org_str = "-/";
                                }
                                if(m6_id.caon){
                                    if(m6_id.caon.orgnum)
                                        m6_caon_org_str = m6_id.caon.orgnum + '/';
                                    if(m6_id.caon.allnum)
                                        m6_caon_all_str = m6_id.caon.allnum + '/';
                                }else{
                                    m6_caon_org_str = '-/';
                                    m6_caon_all_str = "-/";
                                }
                                if(m6_id.pdl){
                                    m6_pdl_org_str = m6_id.pdl.orgnum + '/';
                                    m6_pdl_all_str = m6_id.pdl.allnum + '/';
                                }else{
                                    m6_pdl_org_str = '-/';
                                    m6_pdl_all_str = "-/";
                                }
                                if(m6_id.rel){
                                    m6_rel_org_str = m6_id.rel.orgnum + '/';
                                    m6_rel_all_str = m6_id.rel.allnum + '/';
                                }
                                else{
                                    m6_rel_org_str = '-/';
                                    m6_rel_all_str = "-/";
                                }
                                if(m6_id.caoff){
                                    m6_caoff_org_str = m6_id.caoff.orgnum + '/';
                                    m6_caoff_all_str = m6_id.caoff.allnum + '/';
                                }else{
                                    m6_caoff_org_str = '-/';
                                    m6_caoff_all_str = "-/";
                                }
                                if(m6_id.cooff){
                                    m6_cooff_org_str = m6_id.cooff.orgnum + '/';
                                    m6_cooff_all_str = m6_id.cooff.allnum + '/';
                                }else{
                                    m6_cooff_org_str = '-/';
                                    m6_cooff_all_str = "-/";
                                }
                                if(m6_id.af){
                                    m6_af_org_str = m6_id.af.orgnum + '/';
                                    m6_af_all_str = m6_id.af.allnum + '/';
                                }else{
                                    m6_af_org_str = '-/';
                                    m6_af_all_str = "-/";
                                }
                                if(m6_id.coon){
                                    m6_coon_org_str = m6_id.coon.orgnum + '/';
                                    m6_coon_all_str = m6_id.coon.allnum + '/';
                                }else{
                                    m6_coon_org_str = '-/';
                                    m6_coon_all_str = "-/";
                                }
                            }
                            if(m6_cell){
                                if(m6_cell.bank){
                                    /*
                                    let m6_bank_totalall = 0;
                                    if(m6_cell.bank.tra_allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_cell.bank.tra_allnum*1;
                                    if(m6_cell.bank.ret_allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_cell.bank.ret_allnum*1;
                                    if(m6_cell.bank.allnum)
                                        m6_bank_totalall = m6_bank_totalall + m6_cell.bank.allnum*1;
                                    let m6_bank_totalorg = 0;
                                    if(m6_cell.bank.tra_orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_cell.bank.tra_orgnum*1;
                                    if(m6_cell.bank.ret_orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_cell.bank.ret_orgnum*1;
                                    if(m6_cell.bank.orgnum)
                                        m6_bank_totalorg = m6_bank_totalorg + m6_cell.bank.orgnum*1;
                                    m6_bank_all_str = m6_bank_all_str + m6_bank_totalall;
                                    m6_bank_org_str = m6_bank_org_str + m6_bank_totalorg;*/
                                    if(m6_cell.bank.selfnum)
                                        m6_bank_self_str = m6_bank_self_str + m6_cell.bank.selfnum;
                                    if(m6_cell.bank.allnum)
                                        m6_bank_all_str = m6_bank_all_str + m6_cell.bank.allnum;
                                    if(m6_cell.bank.orgnum)
                                        m6_bank_org_str = m6_bank_org_str + m6_cell.bank.orgnum;
                                    m6_bank_week_all_str = m6_bank_week_all_str + m6_cell.bank.week_allnum;
                                    m6_bank_week_org_str = m6_bank_week_org_str + m6_cell.bank.week_orgnum;
                                    m6_bank_night_all_str = m6_bank_night_all_str + m6_cell.bank.night_allnum;
                                    m6_bank_night_org_str = m6_bank_night_org_str + m6_cell.bank.night_orgnum;
                                }else{
                                    m6_bank_all_str = m6_bank_all_str + "-";
                                    m6_bank_org_str = m6_bank_org_str + "-";
                                    m6_bank_week_all_str = m6_bank_week_all_str + "-";
                                    m6_bank_week_org_str = m6_bank_week_org_str + "-";
                                    m6_bank_night_all_str = m6_bank_night_all_str + "-";
                                    m6_bank_night_org_str = m6_bank_night_org_str + "-";
                                }
                                if(m6_cell.nbank){
                                    /*
                                    let m6_nbank_totalall = 0;
                                    if(m6_cell.nbank.tra_allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_cell.nbank.tra_allnum*1;
                                    if(m6_cell.nbank.ret_allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_cell.nbank.ret_allnum*1;
                                    if(m6_cell.nbank.allnum)
                                        m6_nbank_totalall = m6_nbank_totalall + m6_cell.nbank.allnum*1;
                                    let m6_nbank_totalorg = 0;
                                    if(m6_cell.nbank.tra_orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_cell.nbank.tra_orgnum*1;
                                    if(m6_cell.nbank.ret_orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_cell.nbank.ret_orgnum*1;
                                    if(m6_cell.nbank.orgnum)
                                        m6_nbank_totalorg = m6_nbank_totalorg + m6_cell.nbank.orgnum*1;
                                    m6_nbank_all_str = m6_nbank_all_str + m6_nbank_totalall;
                                    m6_nbank_org_str = m6_nbank_org_str + m6_nbank_totalorg;*/
                                    if(m6_cell.nbank.selfnum)
                                        m6_nbank_self_str = m6_nbank_self_str + m6_cell.nbank.selfnum;
                                     if(m6_cell.nbank.allnum)
                                        m6_nbank_all_str = m6_nbank_all_str + m6_cell.nbank.allnum;
                                    if(m6_cell.nbank.orgnum)
                                        m6_nbank_org_str = m6_nbank_org_str + m6_cell.nbank.orgnum;
                                    m6_nbank_week_all_str = m6_nbank_week_all_str + m6_cell.nbank.week_allnum;
                                    m6_nbank_week_org_str = m6_nbank_week_org_str + m6_cell.nbank.week_orgnum;
                                    m6_nbank_night_all_str = m6_nbank_night_all_str + m6_cell.nbank.night_allnum;
                                    m6_nbank_night_org_str = m6_nbank_night_org_str + m6_cell.nbank.night_orgnum;
                                }else{
                                    m6_nbank_all_str = m6_nbank_all_str + "-";
                                    m6_nbank_org_str = m6_nbank_org_str + "-";
                                    m6_nbank_week_all_str = m6_nbank_week_all_str + "-";
                                    m6_nbank_week_org_str = m6_nbank_week_org_str + "-";
                                    m6_nbank_night_all_str = m6_nbank_night_all_str + "-";
                                    m6_nbank_night_org_str = m6_nbank_night_org_str + "-";
                                }
                                if(m6_cell.caon){
                                    if(m6_cell.caon.orgnum)
                                        m6_caon_org_str = m6_caon_org_str + m6_cell.caon.orgnum;
                                    if(m6_cell.caon.allnum)
                                        m6_caon_all_str = m6_caon_all_str + m6_cell.caon.allnum;
                                }else{
                                    m6_caon_org_str = m6_caon_org_str + '-';
                                    m6_caon_all_str = m6_caon_all_str + '-';
                                }
                                if(m6_cell.pdl){
                                    m6_pdl_org_str = m6_pdl_org_str + m6_cell.pdl.orgnum;
                                    m6_pdl_all_str = m6_pdl_all_str + m6_cell.pdl.allnum;
                                }else{
                                    m6_pdl_org_str = m6_pdl_org_str + '-';
                                    m6_pdl_all_str = m6_pdl_all_str + '-';
                                }
                                if(m6_cell.rel){
                                    m6_rel_org_str = m6_rel_org_str + m6_cell.rel.orgnum;
                                    m6_rel_all_str = m6_rel_all_str + m6_cell.rel.allnum;
                                }
                                else{
                                    m6_rel_org_str = m6_rel_org_str + '-';
                                    m6_rel_all_str = m6_rel_all_str + '-';
                                }
                                if(m6_cell.caoff){
                                    m6_caoff_org_str = m6_caoff_org_str + m6_cell.caoff.orgnum;
                                    m6_caoff_all_str = m6_caoff_all_str + m6_cell.caoff.allnum;
                                }else{
                                    m6_caoff_org_str = m6_caoff_org_str + '-';
                                    m6_caoff_all_str = m6_caoff_all_str + '-';
                                }
                                if(m6_cell.cooff){
                                    m6_cooff_org_str = m6_cooff_org_str + m6_cell.cooff.orgnum;
                                    m6_cooff_all_str = m6_cooff_all_str + m6_cell.cooff.allnum;
                                }else{
                                    m6_cooff_org_str = m6_cooff_org_str + '-';
                                    m6_cooff_all_str = m6_cooff_all_str + '-';
                                }
                                if(m6_cell.af){
                                    m6_af_org_str = m6_af_org_str + m6_cell.af.orgnum;
                                    m6_af_all_str = m6_af_all_str + m6_cell.af.allnum;
                                }else{
                                    m6_af_org_str = m6_af_org_str + '-';
                                    m6_af_all_str = m6_af_all_str + '-';
                                }
                                if(m6_cell.coon){
                                    m6_coon_org_str = m6_coon_org_str + m6_cell.coon.orgnum;
                                    m6_coon_all_str = m6_coon_all_str + m6_cell.coon.allnum;
                                }else{
                                    m6_coon_org_str = m6_coon_org_str + '-';
                                    m6_coon_all_str = m6_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!m6_id && !m6_cell){
                                m6_bank_all_str = "-";
                                m6_bank_org_str = "-";
                                m6_bank_week_all_str = "-";
                                m6_bank_week_org_str = "-";
                                m6_bank_night_all_str = "-";
                                m6_bank_night_org_str = "-";
                                m6_nbank_all_str = "-";
                                m6_nbank_org_str = "-";
                                m6_nbank_week_all_str = "-";
                                m6_nbank_week_org_str = "-";
                                m6_nbank_night_all_str = "-";
                                m6_nbank_night_org_str = "-";
                                m6_caon_org_str = '-';
                                m6_caon_all_str = '-';
                                m6_pdl_org_str = '-';
                                m6_pdl_all_str = '-';
                                m6_rel_org_str = '-';
                                m6_rel_all_str = '-';
                                m6_caoff_org_str = '-';
                                m6_caoff_all_str = '-';
                                m6_cooff_org_str = '-';
                                m6_cooff_all_str = '-';
                                m6_af_org_str = '-';
                                m6_af_all_str = '-';
                                m6_coon_org_str = '-';
                                m6_coon_all_str = '-';
                            }
                            bank.m6org = m6_bank_org_str;
                            bank.m6count = m6_bank_all_str;
                            nbank.m6org = m6_nbank_org_str;
                            nbank.m6count = m6_nbank_all_str;
                            bank_night.m6org = m6_bank_night_org_str;
                            bank_night.m6count = m6_bank_night_all_str;
                            nbank_night.m6org = m6_nbank_night_org_str;
                            nbank_night.m6count = m6_nbank_night_all_str;
                            bank_week.m6org = m6_bank_week_org_str;
                            bank_week.m6count = m6_bank_week_all_str;
                            nbank_week.m6org = m6_nbank_week_org_str;
                            nbank_week.m6count = m6_nbank_week_all_str;
                            caon.m6org = m6_caon_org_str;
                            caon.m6count = m6_caon_all_str;
                            pdl.m6org = m6_pdl_org_str;
                            pdl.m6count = m6_pdl_all_str;
                            rel.m6org = m6_rel_org_str;
                            rel.m6count = m6_rel_all_str;
                            caoff.m6org = m6_caoff_org_str;
                            caoff.m6count = m6_caoff_all_str;
                            cooff.m6org = m6_cooff_org_str;
                            cooff.m6count = m6_cooff_all_str;
                            af.m6org = m6_af_org_str;
                            af.m6count = m6_af_all_str;
                            coon.m6org = m6_coon_org_str;
                            coon.m6count = m6_coon_all_str;
                            bank_self.m6 = m6_bank_self_str;
                            nbank_self.m6 = m6_nbank_self_str;
                        }
                        if(key == 'm12'){
                            let m12_id = this.strategy.ApplyLoanStr[key].id;
                            let m12_cell = this.strategy.ApplyLoanStr[key].cell;
                            let m12_caon_org_str = '';
                            let m12_caon_all_str = '';
                            let m12_pdl_org_str = '';
                            let m12_pdl_all_str = '';
                            let m12_rel_org_str = "";
                            let m12_rel_all_str = "";
                            let m12_caoff_all_str = "";
                            let m12_caoff_org_str = "";
                            let m12_cooff_all_str = "";
                            let m12_cooff_org_str = "";
                            let m12_af_all_str = "";
                            let m12_af_org_str = "";
                            let m12_coon_all_str = "";
                            let m12_coon_org_str = "";
                            let m12_bank_all_str = "";
                            let m12_bank_org_str = "";
                            let m12_nbank_all_str = "";
                            let m12_nbank_org_str = "";
                            let m12_bank_week_all_str = "";
                            let m12_bank_week_org_str = "";
                            let m12_nbank_week_all_str = "";
                            let m12_nbank_week_org_str = "";
                            let m12_bank_night_all_str = "";
                            let m12_bank_night_org_str = '';
                            let m12_nbank_night_all_str = "";
                            let m12_nbank_night_org_str = "";
                            let m12_bank_self_str = "";
                            let m12_nbank_self_str = "";
                            if(m12_id){
                                if(m12_id.bank){
                                    if(m12_id.bank.selfnum)
                                        m12_bank_self_str = m12_id.bank.selfnum + "/";
                                    if(m12_id.bank.allnum)
                                        m12_bank_all_str = m12_id.bank.allnum + "/";
                                    if(m12_id.bank.orgnum)
                                        m12_bank_org_str = m12_id.bank.orgnum + "/";
                                    /*
                                    let m12_bank_totalall = 0;
                                    if(m12_id.bank.tra_allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_id.bank.tra_allnum*1;
                                    if(m12_id.bank.ret_allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_id.bank.ret_allnum*1;
                                    if(m12_id.bank.allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_id.bank.allnum*1;
                                    let m12_bank_totalorg = 0;
                                    if(m12_id.bank.tra_orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_id.bank.tra_orgnum*1;
                                    if(m12_id.bank.ret_orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_id.bank.ret_orgnum*1;
                                    if(m12_id.bank.orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_id.bank.orgnum*1;
                                    m12_bank_all_str = m12_bank_totalall + "/";
                                    m12_bank_org_str = m12_bank_totalorg + "/";*/
                                    m12_bank_week_all_str = m12_id.bank.week_allnum + "/";
                                    m12_bank_week_org_str = m12_id.bank.week_orgnum + "/";
                                    m12_bank_night_all_str = m12_id.bank.night_allnum + "/";
                                    m12_bank_night_org_str = m12_id.bank.night_orgnum + "/";
                                }else{
                                    m12_bank_all_str = "-/";
                                    m12_bank_org_str = "-/";
                                    m12_bank_week_all_str = "-/";
                                    m12_bank_week_org_str = "-/";
                                    m12_bank_night_all_str = "-/";
                                    m12_bank_night_org_str = "-/";
                                }
                                if(m12_id.nbank){
                                    if(m12_id.nbank.selfnum)
                                        m12_nbank_self_str = m12_id.nbank.selfnum + "/";
                                    if(m12_id.nbank.allnum)
                                        m12_nbank_all_str = m12_id.nbank.allnum + "/";
                                    if(m12_id.nbank.orgnum)
                                        m12_nbank_org_str = m12_id.nbank.orgnum + "/";
                                    /*
                                    let m12_nbank_totalall = 0;
                                    if(m12_id.nbank.tra_allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_id.nbank.tra_allnum*1;
                                    if(m12_id.nbank.ret_allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_id.nbank.ret_allnum*1;
                                    if(m12_id.nbank.allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_id.nbank.allnum*1;
                                    let m12_nbank_totalorg = 0;
                                    if(m12_id.nbank.tra_orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_id.nbank.tra_orgnum*1;
                                    if(m12_id.nbank.ret_orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_id.nbank.ret_orgnum*1;
                                    if(m12_id.nbank.orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_id.nbank.orgnum*1;
                                    m12_nbank_all_str = m12_nbank_totalall + "/";
                                    m12_nbank_org_str = m12_nbank_totalorg + "/";*/
                                    m12_nbank_week_all_str = m12_id.nbank.week_allnum + "/";
                                    m12_nbank_week_org_str = m12_id.nbank.week_orgnum + "/";
                                    m12_nbank_night_all_str = m12_id.nbank.night_allnum + "/";
                                    m12_nbank_night_org_str = m12_id.nbank.night_orgnum + "/";
                                }else{
                                    m12_nbank_all_str = "-/";
                                    m12_nbank_org_str = "-/";
                                    m12_nbank_week_all_str = "-/";
                                    m12_nbank_week_org_str = "-/";
                                    m12_nbank_night_all_str = "-/";
                                    m12_nbank_night_org_str = "-/";
                                }
                                if(m12_id.caon){
                                    if(m12_id.caon.orgnum)
                                        m12_caon_org_str = m12_id.caon.orgnum + '/';
                                    if(m12_id.caon.allnum)
                                        m12_caon_all_str = m12_id.caon.allnum + '/';
                                }else{
                                    m12_caon_org_str = '-/';
                                    m12_caon_all_str = "-/";
                                }
                                if(m12_id.pdl){
                                    m12_pdl_org_str = m12_id.pdl.orgnum + '/';
                                    m12_pdl_all_str = m12_id.pdl.allnum + '/';
                                }else{
                                    m12_pdl_org_str = '-/';
                                    m12_pdl_all_str = "-/";
                                }
                                if(m12_id.rel){
                                    m12_rel_org_str = m12_id.rel.orgnum + '/';
                                    m12_rel_all_str = m12_id.rel.allnum + '/';
                                }
                                else{
                                    m12_rel_org_str = '-/';
                                    m12_rel_all_str = "-/";
                                }
                                if(m12_id.caoff){
                                    m12_caoff_org_str = m12_id.caoff.orgnum + '/';
                                    m12_caoff_all_str = m12_id.caoff.allnum + '/';
                                }else{
                                    m12_caoff_org_str = '-/';
                                    m12_caoff_all_str = "-/";
                                }
                                if(m12_id.cooff){
                                    m12_cooff_org_str = m12_id.cooff.orgnum + '/';
                                    m12_cooff_all_str = m12_id.cooff.allnum + '/';
                                }else{
                                    m12_cooff_org_str = '-/';
                                    m12_cooff_all_str = "-/";
                                }
                                if(m12_id.af){
                                    m12_af_org_str = m12_id.af.orgnum + '/';
                                    m12_af_all_str = m12_id.af.allnum + '/';
                                }else{
                                    m12_af_org_str = '-/';
                                    m12_af_all_str = "-/";
                                }
                                if(m12_id.coon){
                                    m12_coon_org_str = m12_id.coon.orgnum + '/';
                                    m12_coon_all_str = m12_id.coon.allnum + '/';
                                }else{
                                    m12_coon_org_str = '-/';
                                    m12_coon_all_str = "-/";
                                }
                            }
                            if(m12_cell){
                                if(m12_cell.bank){
                                    /*
                                    let m12_bank_totalall = 0;
                                    if(m12_cell.bank.tra_allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_cell.bank.tra_allnum*1;
                                    if(m12_cell.bank.ret_allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_cell.bank.ret_allnum*1;
                                    if(m12_cell.bank.allnum)
                                        m12_bank_totalall = m12_bank_totalall + m12_cell.bank.allnum*1;
                                    let m12_bank_totalorg = 0;
                                    if(m12_cell.bank.tra_orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_cell.bank.tra_orgnum*1;
                                    if(m12_cell.bank.ret_orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_cell.bank.ret_orgnum*1;
                                    if(m12_cell.bank.orgnum)
                                        m12_bank_totalorg = m12_bank_totalorg + m12_cell.bank.orgnum*1;
                                    m12_bank_all_str = m12_bank_all_str + m12_bank_totalall;
                                    m12_bank_org_str = m12_bank_org_str + m12_bank_totalorg;*/
                                    if(m12_cell.bank.selfnum)
                                        m12_bank_self_str = m12_bank_self_str + m12_cell.bank.selfnum;
                                    if(m12_cell.bank.allnum)
                                        m12_bank_all_str = m12_bank_all_str + m12_cell.bank.allnum;
                                    if(m12_cell.bank.orgnum)
                                        m12_bank_org_str = m12_bank_org_str + m12_cell.bank.orgnum;
                                    m12_bank_week_all_str = m12_bank_week_all_str + m12_cell.bank.week_allnum;
                                    m12_bank_week_org_str = m12_bank_week_org_str + m12_cell.bank.week_orgnum;
                                    m12_bank_night_all_str = m12_bank_night_all_str + m12_cell.bank.night_allnum;
                                    m12_bank_night_org_str = m12_bank_night_org_str + m12_cell.bank.night_orgnum;
                                }else{
                                    m12_bank_all_str = m12_bank_all_str + "-";
                                    m12_bank_org_str = m12_bank_org_str + "-";
                                    m12_bank_week_all_str = m12_bank_week_all_str + "-";
                                    m12_bank_week_org_str = m12_bank_week_org_str + "-";
                                    m12_bank_night_all_str = m12_bank_night_all_str + "-";
                                    m12_bank_night_org_str = m12_bank_night_org_str + "-";
                                }
                                if(m12_cell.nbank){
                                    /*
                                    let m12_nbank_totalall = 0;
                                    if(m12_cell.nbank.tra_allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_cell.nbank.tra_allnum*1;
                                    if(m12_cell.nbank.ret_allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_cell.nbank.ret_allnum*1;
                                    if(m12_cell.nbank.allnum)
                                        m12_nbank_totalall = m12_nbank_totalall + m12_cell.nbank.allnum*1;
                                    let m12_nbank_totalorg = 0;
                                    if(m12_cell.nbank.tra_orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_cell.nbank.tra_orgnum*1;
                                    if(m12_cell.nbank.ret_orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_cell.nbank.ret_orgnum*1;
                                    if(m12_cell.nbank.orgnum)
                                        m12_nbank_totalorg = m12_nbank_totalorg + m12_cell.nbank.orgnum*1;
                                    m12_nbank_all_str = m12_nbank_all_str + m12_nbank_totalall;
                                    m12_nbank_org_str = m12_nbank_org_str + m12_nbank_totalorg;*/
                                    if(m12_cell.nbank.selfnum)
                                        m12_nbank_self_str = m12_nbank_self_str + m12_cell.nbank.selfnum;
                                     if(m12_cell.nbank.allnum)
                                        m12_nbank_all_str = m12_nbank_all_str + m12_cell.nbank.allnum;
                                    if(m12_cell.nbank.orgnum)
                                        m12_nbank_org_str = m12_nbank_org_str + m12_cell.nbank.orgnum;
                                    m12_nbank_week_all_str = m12_nbank_week_all_str + m12_cell.nbank.week_allnum;
                                    m12_nbank_week_org_str = m12_nbank_week_org_str + m12_cell.nbank.week_orgnum;
                                    m12_nbank_night_all_str = m12_nbank_night_all_str + m12_cell.nbank.night_allnum;
                                    m12_nbank_night_org_str = m12_nbank_night_org_str + m12_cell.nbank.night_orgnum;
                                }else{
                                    m12_nbank_all_str = m12_nbank_all_str + "-";
                                    m12_nbank_org_str = m12_nbank_org_str + "-";
                                    m12_nbank_week_all_str = m12_nbank_week_all_str + "-";
                                    m12_nbank_week_org_str = m12_nbank_week_org_str + "-";
                                    m12_nbank_night_all_str = m12_nbank_night_all_str + "-";
                                    m12_nbank_night_org_str = m12_nbank_night_org_str + "-";
                                }
                                if(m12_cell.caon){
                                    if(m12_cell.caon.orgnum)
                                        m12_caon_org_str = m12_caon_org_str + m12_cell.caon.orgnum;
                                    if(m12_cell.caon.allnum)
                                        m12_caon_all_str = m12_caon_all_str + m12_cell.caon.allnum;
                                }else{
                                    m12_caon_org_str = m12_caon_org_str + '-';
                                    m12_caon_all_str = m12_caon_all_str + '-';
                                }
                                if(m12_cell.pdl){
                                    m12_pdl_org_str = m12_pdl_org_str + m12_cell.pdl.orgnum;
                                    m12_pdl_all_str = m12_pdl_all_str + m12_cell.pdl.allnum;
                                }else{
                                    m12_pdl_org_str = m12_pdl_org_str + '-';
                                    m12_pdl_all_str = m12_pdl_all_str + '-';
                                }
                                if(m12_cell.rel){
                                    m12_rel_org_str = m12_rel_org_str + m12_cell.rel.orgnum;
                                    m12_rel_all_str = m12_rel_all_str + m12_cell.rel.allnum;
                                }
                                else{
                                    m12_rel_org_str = m12_rel_org_str + '-';
                                    m12_rel_all_str = m12_rel_all_str + '-';
                                }
                                if(m12_cell.caoff){
                                    m12_caoff_org_str = m12_caoff_org_str + m12_cell.caoff.orgnum;
                                    m12_caoff_all_str = m12_caoff_all_str + m12_cell.caoff.allnum;
                                }else{
                                    m12_caoff_org_str = m12_caoff_org_str + '-';
                                    m12_caoff_all_str = m12_caoff_all_str + '-';
                                }
                                if(m12_cell.cooff){
                                    m12_cooff_org_str = m12_cooff_org_str + m12_cell.cooff.orgnum;
                                    m12_cooff_all_str = m12_cooff_all_str + m12_cell.cooff.allnum;
                                }else{
                                    m12_cooff_org_str = m12_cooff_org_str + '-';
                                    m12_cooff_all_str = m12_cooff_all_str + '-';
                                }
                                if(m12_cell.af){
                                    m12_af_org_str = m12_af_org_str + m12_cell.af.orgnum;
                                    m12_af_all_str = m12_af_all_str + m12_cell.af.allnum;
                                }else{
                                    m12_af_org_str = m12_af_org_str + '-';
                                    m12_af_all_str = m12_af_all_str + '-';
                                }
                                if(m12_cell.coon){
                                    m12_coon_org_str = m12_coon_org_str + m12_cell.coon.orgnum;
                                    m12_coon_all_str = m12_coon_all_str + m12_cell.coon.allnum;
                                }else{
                                    m12_coon_org_str = m12_coon_org_str + '-';
                                    m12_coon_all_str = m12_coon_all_str + '-';
                                }
                            }
                            //bank
                            if(!m12_id && !m12_cell){
                                m12_bank_all_str = "-";
                                m12_bank_org_str = "-";
                                m12_bank_week_all_str = "-";
                                m12_bank_week_org_str = "-";
                                m12_bank_night_all_str = "-";
                                m12_bank_night_org_str = "-";
                                m12_nbank_all_str = "-";
                                m12_nbank_org_str = "-";
                                m12_nbank_week_all_str = "-";
                                m12_nbank_week_org_str = "-";
                                m12_nbank_night_all_str = "-";
                                m12_nbank_night_org_str = "-";
                                m12_caon_org_str = '-';
                                m12_caon_all_str = '-';
                                m12_pdl_org_str = '-';
                                m12_pdl_all_str = '-';
                                m12_rel_org_str = '-';
                                m12_rel_all_str = '-';
                                m12_caoff_org_str = '-';
                                m12_caoff_all_str = '-';
                                m12_cooff_org_str = '-';
                                m12_cooff_all_str = '-';
                                m12_af_org_str = '-';
                                m12_af_all_str = '-';
                                m12_coon_org_str = '-';
                                m12_coon_all_str = '-';
                            }
                            bank.m12org = m12_bank_org_str;
                            bank.m12count = m12_bank_all_str;
                            nbank.m12org = m12_nbank_org_str;
                            nbank.m12count = m12_nbank_all_str;
                            bank_night.m12org = m12_bank_night_org_str;
                            bank_night.m12count = m12_bank_night_all_str;
                            nbank_night.m12org = m12_nbank_night_org_str;
                            nbank_night.m12count = m12_nbank_night_all_str;
                            bank_week.m12org = m12_bank_week_org_str;
                            bank_week.m12count = m12_bank_week_all_str;
                            nbank_week.m12org = m12_nbank_week_org_str;
                            nbank_week.m12count = m12_nbank_week_all_str;
                            caon.m12org = m12_caon_org_str;
                            caon.m12count = m12_caon_all_str;
                            pdl.m12org = m12_pdl_org_str;
                            pdl.m12count = m12_pdl_all_str;
                            rel.m12org = m12_rel_org_str;
                            rel.m12count = m12_rel_all_str;
                            caoff.m12org = m12_caoff_org_str;
                            caoff.m12count = m12_caoff_all_str;
                            cooff.m12org = m12_cooff_org_str;
                            cooff.m12count = m12_cooff_all_str;
                            af.m12org = m12_af_org_str;
                            af.m12count = m12_af_all_str;
                            coon.m12org = m12_coon_org_str;
                            coon.m12count = m12_coon_all_str;
                            bank_self.m12 = m12_bank_self_str;
                            nbank_self.m12 = m12_nbank_self_str;
                        }

                    }
                    this.servicedata.push(caon);
                    this.servicedata.push(pdl);
                    this.servicedata.push(rel);
                    this.servicedata.push(caoff);
                    this.servicedata.push(cooff);
                    this.servicedata.push(af);
                    this.servicedata.push(coon);
                    this.customerdata.push(bank);
                    this.customerdata.push(nbank);
                    this.timedata.push(bank_night);
                    this.timedata.push(bank_week);
                    this.timedata.push(nbank_night);
                    this.timedata.push(nbank_week);
                    console.log("servicedata");
                    console.log(this.servicedata);
                    console.log(this.customerdata);

                    //借贷风险勘测
                    let fst = new Object();
                    let lst = new Object();
                    let id_all = new Object();
                    let id_org = new Object();
                    let cell_all = new Object();
                    let cell_org = new Object();
                    fst.type = '最近申请距今天数';
                    
                    lst.type = '最早申请距今天数';
                    
                    id_all.type = "身份证号查询的申请次数";
                    id_all.unit = '次';
                    id_org.type = "身份证号查询的申请机构数";
                    id_org.unit = '个';
                    cell_all.type = "手机号查询的申请次数";
                    cell_all.unit = '次';
                    cell_org.type = "手机号查询的申请机构数";
                    cell_org.unit = '个';
                    for(key in this.strategy.ApplyLoanUsury){
                        let curobj = this.strategy.ApplyLoanUsury[key];
                        let id_obj = curobj.id;

                        let cell_obj = curobj.cell;

                        //console.log(curobj);
                        if(key == 'fst'){
                            if(id_obj){

                                fst.id = id_obj.inteday;
                            }
                            if(cell_obj){
                                fst.cell = cell_obj.inteday;
                            }
                        }
                        else if(key == 'lst'){
                            if(id_obj){

                                lst.id = id_obj.inteday;
                            }
                            if(cell_obj){
                                lst.cell = cell_obj.inteday;
                            }
                        }else{
                            if(id_obj){
                                id_all[key] = id_obj.allnum;
                                id_org[key] = id_obj.orgnum;
                            }
                            if(cell_obj){
                                cell_all[key] = cell_obj.allnum;
                                cell_org[key] = cell_obj.orgnum;
                            }
                        }
                    }
                    this.debtdata.push(id_all);
                    this.debtdata.push(cell_all);
                    this.debtdata.push(id_org);
                    this.debtdata.push(cell_org);
                    this.applydata.push(fst);
                    this.applydata.push(lst);
                    console.log("bank_self");
                    this.selfdata.push(bank_self);
                    console.log("nbank_self");
                    this.selfdata.push(nbank_self);
                    
                    console.log("debtdata");
                    console.log(this.debtdata);
                    console.log("applydata");
                    console.log(this.applydata);

                    //法院裁判文书
                    for(key in this.strategy.ExecutionJud){
                        let curobj = this.strategy.ExecutionJud[key];
                        let obj = new Object();
                        obj.court = curobj.court;//法院名称
                        obj.courtrank = curobj.courtRank;//法院等级
                        obj.casecause = curobj.caseCause;//案由
                        obj.procedure = curobj.trialProcedure;//审理程序
                        obj.level = curobj.eventLevel;//五级分类
                        obj.match = curobj.matchRatio;//匹配度
                        this.executionjud.push(obj);
                    }
                    console.log(this.executionjud);

                    //特殊名单
                    
                    let special = this.strategy.SpecialList_c;
                    for(let key in special){
                        let type = "";
                        if(key == 'id'){
                            //身份证
                            type = "身份证号";
                        }else if(key == 'cell'){
                            //手机号
                            type = "手机号";
                        }else if(key == 'lm_cell'){
                            //其他手机
                            type = "其他手机号";
                        }
                        let curobj = special[key];
                        for(key in curobj){
                            let retobj = new Object();
                            if(key == 'court_bad'){
                                retobj.name = "通过" + type + "查询法院失信人";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'court_executed'){
                                retobj.name = "通过" + type + "查询法院被执行人";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'bank_bad'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'bank_overdue'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'bank_fraud'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'bank_lost'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'bank_refuse'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_bad'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_overdue'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_fraud'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_lost'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_refuse'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_nsloan_bad'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_nsloan_overdue'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_nsloan_fraud'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_nsloan_lost'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_nsloan_refuse'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_sloan_bad'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_sloan_overdue'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_sloan_fraud'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_sloan_lost'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_sloan_refuse'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_cons_bad'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_cons_overdue'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_cons_fraud'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_cons_lost'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_cons_refuse'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_finlea_bad'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_finlea_overdue'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_finlea_fraud'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_finlea_lost'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_finlea_refuse'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_autofin_bad'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_autofin_overdue'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_autofin_fraud'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_autofin_lost'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_autofin_refuse'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_other_bad'){
                                retobj.name = "通过" + type + "查询非银-其他中风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_other_overdue'){
                                retobj.name = "通过" + type + "查询非银-其他一般风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_other_fraud'){
                                retobj.name = "通过" + type + "查询非银-其他资信不佳";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_other_lost'){
                                retobj.name = "通过" + type + "查询非银-其他高风险";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'nbank_other_refuse'){
                                retobj.name = "通过" + type + "查询非银-其他拒绝";
                                if(curobj[key] == '0')
                                    retobj.result = '本人直接命中';
                                if(curobj[key] == '1')
                                    retobj.result = '一度关系命中';
                                if(curobj[key] == '2')
                                    retobj.result = '二度关系命中';
                            }
                            if(key == 'court_bad_allnum'){
                                retobj.name = "通过" + type + "查询法院失信人命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'court_executed_allnum'){
                                retobj.name = "通过" + type + "查询法院被执行人命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'bank_bad_allnum'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'bank_overdue_allnum'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'bank_fraud_allnum'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'bank_lost_allnum'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'bank_refuse_allnum'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_nsloan_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_nsloan_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_nsloan_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_nsloan_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_nsloan_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_sloan_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_sloan_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_sloan_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_sloan_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_sloan_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_cons_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_cons_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_cons_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_cons_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_cons_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_finlea_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_finlea_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_finlea_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_finlea_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_finlea_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_autofin_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_autofin_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_autofin_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_autofin_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_autofin_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_other_bad_allnum'){
                                retobj.name = "通过" + type + "查询非银-其他中风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_other_overdue_allnum'){
                                retobj.name = "通过" + type + "查询非银-其他一般风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_other_fraud_allnum'){
                                retobj.name = "通过" + type + "查询非银-其他资信不佳命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_other_lost_allnum'){
                                retobj.name = "通过" + type + "查询非银-其他高风险命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'nbank_other_refuse_allnum'){
                                retobj.name = "通过" + type + "查询非银-其他拒绝命中次数";
                                retobj.result = curobj[key]+"次";
                            }
                            if(key == 'court_bad_time'){
                                retobj.name = "通过" + type + "查询法院失信人距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'court_executed_time'){
                                retobj.name = "通过" + type + "查询法院被执行人距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'bank_bad_time'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'bank_overdue_time'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'bank_fraud_time'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'bank_lost_time'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'bank_refuse_time'){
                                retobj.name = "通过" + type + "查询银行(含信用卡)拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_bad_time'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_overdue_time'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_fraud_time'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_lost_time'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_refuse_time'){
                                retobj.name = "通过" + type + "查询非银(含全部非银类型)拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_nsloan_bad_time'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_nsloan_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_nsloan_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_nsloan_lost_time'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_nsloan_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-持牌网络小贷拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_sloan_bad_time'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_sloan_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_sloan_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_sloan_lost_time'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_sloan_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-持牌小贷拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_cons_bad_time'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_cons_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_cons_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_cons_lost_time'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_cons_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-持牌消费金融拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_finlea_bad_time'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_finlea_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_finlea_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_finlea_lost_time'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_finlea_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-持牌融资租赁拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_autofin_bad_time'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_autofin_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_autofin_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_autofin_lost_time'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_autofin_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-持牌汽车金融拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_other_bad_time'){
                                retobj.name = "通过" + type + "查询非银-其他中风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_other_overdue_time'){
                                retobj.name = "通过" + type + "查询非银-其他一般风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_other_fraud_time'){
                                retobj.name = "通过" + type + "查询非银-其他资信不佳距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_other_lost_time'){
                                retobj.name = "通过" + type + "查询非银-其他高风险距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            if(key == 'nbank_other_refuse_time'){
                                retobj.name = "通过" + type + "查询非银-其他拒绝距今时间";
                                retobj.result = curobj[key]+"年";
                            }
                            
                            this.speciallist.push(retobj);
                        }
                    }
                })
            }
            
        },
        UploadID(id){
            this.curorderid = id;
            this.uploaddialog = true;
        },
        SaveLicense(){
            this.axios.get(constant.savelicense,{
                headers: {'Content-Type': 'application/json'},
                params:{
                    orderid:this.curorderid,
                    licensefront:this.licensefront,
                    licenseback:this.licenseback,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.uploaddialog = false;
                    this.$router.go(0);
                }else{
                    this.$message.error("上传失败");
                }
            });
        },
        UploadFrontSuccess(response,file){
            console.log(response);
            console.log(file);
            if(file.response.error == 0){
                this.licensefront= file.response.url;
                console.log(this.licensefront);
            }
        },
        UploadBackSuccess(response,file){
            console.log(response);
            console.log(file);
            if(file.response.error == 0){
                this.licenseback= file.response.url;
                console.log(this.licenseback);
            }
        },
        GetCompesation(orderid){
            this.axios.get(constant.getcompesation,{
                headers: {'Content-Type': 'application/json'},
                params:{
                    orderid:orderid
                }
            }).then((response)=>{
                console.log(response.data);
                this.compensationinfo = response.data;
                this.compensation = true;
            })
        },
        GetLogisticDetail(logno){
            this.axios.get(constant.get_logistic_detail,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    logno: logno
                }
            }).then((response)=>{
                console.log(response.data);
                this.expName = response.data.result.expName;
                this.number = response.data.result.number;
                this.logdetail = response.data.result.list;
                this.logisticdetail = true;
            });
            return false;
        },
        GetOrderBill(orderid){
            console.log(orderid);
            this.axios.get(constant.get_order_bill,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    orderid:orderid
                }
            }).then((response)=>{
                console.log(response.data);
                this.billdata = response.data.billdata;
                this.summarydata[0].overdue = ((response.data.otherinfo.Overdue*1).toFixed(2))*1;
                this.summarydata[0].mitigate = ((response.data.otherinfo.Mitigate*1).toFixed(2))*1;
                this.summarydata[0].needoverdue = ((response.data.otherinfo.Overdue*1 - response.data.otherinfo.Mitigate*1-response.data.otherinfo.PayOverdue*1).toFixed(2))*1;
                this.summarydata[0].payoverdue = ((response.data.otherinfo.PayOverdue*1).toFixed(2))*1;
                
            });
            this.orderbill = true;
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        GetTrade(orderid){
            this.curorderid = orderid;
            this.axios.get(constant.get_trade,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    orderid:orderid,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tradedata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
                this.tradebill = true;
            });
        },
        GoBack(){
            //this.$router.go(-1);
            this.$router.push({path:'/orderlist',query:{curpage:this.retparams.curpage,pagesize:this.retparams.pagesize,status:this.retparams.status,range:this.retparams.range,keyword:this.retparams.keyword,type:this.retparams.type}});
        }
    }
}
</script>